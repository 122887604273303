<template>
  <div>
    <div v-if="pay.kktName!=null">
      <v-icon size="18" class="d-inline-block mr-2" style="margin-top:-2px">mdi-cash-register</v-icon>
      <div class="d-inline-block">{{pay.kktName}}</div>
    </div>
    <div v-if="pay.terminalName!=null">
      <v-icon size="18" class="d-inline-block mr-2" style="margin-top:-2px">mdi-credit-card-outline</v-icon>
      <div class="d-inline-block">{{pay.terminalName}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props:['pay'],
}
</script>