import { render, staticRenderFns } from "./CardView.vue?vue&type=template&id=04796b50"
import script from "./CardView.vue?vue&type=script&lang=js"
export * from "./CardView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports