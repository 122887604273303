<template>
  <v-autocomplete
      v-model="selected"
      :items="items"
      :search-input.sync="text"
      label="Теги"
      outlined
      dense
      hide-details
      multiple
      class="mb-4"
      item-text="name"
      item-value="id"
      @click="items.length===0 || items.length===defaultItems.length ? load() : null"
      @change = "save()"
  >
    <template v-slot:append>
      <v-sheet v-if="state.isLoading || saveState.isLoading" width="24" height="24" class="d-flex align-center justify-center">
        <v-progress-circular color="primary" size="16" width="2" indeterminate/>
      </v-sheet>
      <v-icon v-if="saveState.isSuccess" color="success">mdi-check</v-icon>
      <v-icon v-if="saveState.isError" color="error">mdi-information-outline</v-icon>
    </template>
    <template v-slot:no-data>
      <div v-if="state.isLoading"></div>
      <v-btn v-else-if="state.isSuccess" @click="create()" depressed small class="ml-2">Создать</v-btn>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title>Загрузка...</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{item}">
      <v-list-item-title>
        <div class="py-2">
          {{item.name}}
        </div>
      </v-list-item-title>
    </template>
    <template v-slot:selection="{item}">
      <v-chip class="mt-2" small>
        <v-row no-gutters>
          <v-col>{{item.name}}</v-col>
          <v-col cols="auto" class="pl-2">
            <v-icon small @click.stop="selected = selected.filter((v)=>v!==item.id);save()">mdi-close-circle</v-icon>
          </v-col>
        </v-row>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>

import State from "@/plugins/state";
import Api from "@/Api";
import axios from "axios";

export default {
  props:[
    'userProfileId',
    'defaultItems'
  ],
  data:()=>({
    items:[],
    text:null,
    selected: [],
    firstOpen: false,
    state: new State(),
    saveState: new State(),
  }),
  mounted(){
    this.items = this.defaultItems;
    this.selected = [];
    this.defaultItems.forEach(v=>{
      this.selected.push(v.id);
    });
  },
  methods:{
    load(callback=null){
      let query = `
        query Query{
          UserProfileTag{
            id
            name
          }
        }
      `;
      this.state.stateLoading();
      Api.graphql(query, {}, (data)=>{
        this.state.stateSuccess()
        data.UserProfileTag.forEach(v=>{
          if(this.items.filter(v1=>v1.name===v.name).length===0)
            this.items.push(v);
        })
        this.items = this.items.sort((a,b)=>a.id===b.id ? 0 : a.id>b.id ? -1 : 1)
        if(callback!==null)
          callback();
      }, (e)=>{
        this.state.stateError(e)
      });
    },
    create(){
      this.state.stateLoading();
      axios.post(Api.host+'/userProfileTag/', {
        name:this.text
      }).then(()=>{
        this.load(()=>{
          this.selected.push(this.items.filter(v=>v.name===this.text)[0].id);
          this.save();
          this.text = null;
        })
      })
    },
    save() {
      this.saveState.stateLoading();
      this.$tools.throttle(()=>{
        axios.put(Api.host+'/userProfileTag/', {
          userProfileId:this.userProfileId,
          ids:this.selected
        }).then(()=>{
          this.saveState.stateSuccess();
        }).catch(()=>{
          this.saveState.stateError();
        })
      });
    }
  }
}
</script>