<template>
  <div>
    <v-form v-model="valid">
      <v-dialog
          v-model="localOpen"
          @click:outside="localOpen=false"
          :max-width="typeof width==='undefined' ? 425 : width"
          scrollable
      >
        <v-card>
          <v-sheet color="grey lighten-5">
            <v-toolbar dense elevation="0" color="transparent" max-height="48px">
              <v-toolbar-title>{{typeof name==='undefined' ? '' : ' '+name}} {{this.localItem===null || typeof this.localItem.id==='undefined' || this.localItem.id===null ? '' : '#'+this.localItem.id}}</v-toolbar-title>
              <v-spacer/>
              <slot name="actions" v-bind:localItem="localItem"/>
              <v-btn icon @click="localOpen=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <slot v-if="localItem!==null" name="header" v-bind:localItem="localItem"/>
          </v-sheet>
          <v-divider/>
          <div :class="'overflow-y-auto'+(typeof contentPaddingRemove==='undefined' ? ' pt-4 px-4' : '')">
            <v-sheet :height="typeof fixedHeight==='undefined' ? null : $tools.pageHeight()-220" class="full-width position-relative">
              <slot v-if="localItem!==null" v-bind:localItem="localItem" v-bind:height="typeof fixedHeight==='undefined' ? null : $tools.pageHeight()-220"/>
            </v-sheet>
          </div>
          <v-divider v-if="typeof footerHide==='undefined' || footerHide===true"/>
          <v-row v-if="typeof footerHide==='undefined' || footerHide===true" no-gutters class="mx-4 my-2 text-right">
            <v-col cols="auto">
              <slot name="footerActions" v-if="localItem!==null" v-bind:localItem="localItem"/>
            </v-col>
            <v-col>
              <v-sheet height="36">
                <v-btn
                    v-if="typeof submitText==='undefined' || submitText!==null"
                    @click="localLoading=true;$tools.throttle(()=>{localLoading=false},1000);$emit('submit',localItem)"
                    :disabled="!valid"
                    :loading="localLoading"
                    color="primary" text type="submit"
                >
                  {{typeof submitText==='undefined' ? 'OK' : submitText}}
                </v-btn>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>

export default {
  props:[
    'name',
    'item',
    'width',
    'fixedHeight',
    'contentPaddingRemove',
    'loading',
    'footerHide',
    'submitText'
  ],
  data:()=>({
    localItem:null,
    localOpen:false,
    localLoading:false,
    valid:true,
  }),
  watch:{
    item(){
      if(this.item===null) {
        this.localOpen = false;
        setTimeout(()=>{
          this.localItem = null;
        },200)
      } else {
        this.localItem = this.$tools.copy(this.item);
        this.localOpen = true;
        this.localLoading = false;
      }
    },
    localOpen(){
      if(!this.localOpen)
        this.$emit('update:item', null);
    },
    loading(){
      this.localLoading = this.loading;
    }
  }
}
</script>