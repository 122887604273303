<template>
  <div>
    <div v-if="user===null">
      <div class="pt-2">
        <v-btn
          small depressed
          @click="
            mainState.modalOpen();
            mainState.stateInit();
            searchPhone='+7';
            searchFirstName=null;
            searchLastName=null;
            searchSecondName=null;
            mainState.step = 0;
            userSearchMatch = false;
          ">
          <v-icon small class="pr-2">mdi-magnify</v-icon>Поиск
        </v-btn>
      </div>
    </div>
    <div v-else>
      <div class="title font-weight-regular mb-1">
        <span class="mr-2">{{user.lastName}}</span>
        <span class="mr-2">{{user.firstName}}</span>
        <span class="mr-2">{{user.secondName}}</span>
        <v-btn
            icon depressed :href="'/panel/user/client/'+user.id+'/'" target="_blank">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </div>
      <div class="font-weight-regular">
        <span class="mr-2"><v-icon class="mr-2 mt-n1" small>mdi-account-outline</v-icon>{{user.userProfile.gender==='male' ? 'Мужчина' : 'Женщина'}}</span>
        <span class="mr-2" v-if="birth!=='Invalid date'"><v-icon class="mr-2 mt-n1" small>mdi-calendar-star</v-icon>{{birth}}</span>
        <call-phone :phone="user.phone" class="mr-2"/>
        <span v-if="!$tools.isEmpty(user.userProfile.workOrgName)" class="mr-2"><v-icon class="mr-2" small>mdi-office-building</v-icon>{{user.userProfile.workOrgName}}</span>
        <call-phone v-if="!$tools.isEmpty(user.userProfile.workOrgPhone)" :phone="user.userProfile.workOrgPhone"/>
      </div>

      <v-btn @click="deleteState.modalOpen()" small class="mt-2" depressed><v-icon small class="pr-2">mdi-trash-can-outline</v-icon>Удалить</v-btn>
    </div>
    <dialog-view
        :open="mainState.isModalOpen"
        :title="mainState.step===0&&!userSearchMatch ? 'Поиск представителя' : mainState.step===0&&userSearchMatch ? 'Найдено совпадение!' : 'Создать представителя'"
        @cancel="close()"
    >
      <template v-slot:content>
        <div v-if="mainState.isLoading" class="py-4 text-center">
          <v-progress-circular indeterminate color="primary"/>
        </div>
        <div v-if="mainState.step===0">
          <v-row v-if="mainState.isInit">
            <v-col cols="12">
              Укажите номер телефона или Ф.И.О
            </v-col>
            <v-col cols="12">
              <!-- <v-text-field
                  ref="searchPhone"
                  v-model="searchPhone"
                  type="tel" dense clearable label="Номер телефона" outlined hide-details="auto" v-mask="'+7 (###) ###-##-##'"/> -->
                  <phone-field ref="searchPhone" :phone.sync="searchPhone" label="Телефон"/>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="searchLastName"
                  dense clearable label="Фамилия" outlined hide-details="auto" @keyup="$tools.firstLatterUppercase($event)"/>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="searchFirstName"
                  dense clearable label="Имя" outlined hide-details="auto" @keyup="$tools.firstLatterUppercase($event)"/>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="searchSecondName"
                  dense clearable label="Отчество" outlined hide-details="auto" @keyup="$tools.firstLatterUppercase($event)"/>
            </v-col>
          </v-row>
          <div v-if="mainState.isSuccess">
            <div v-if="searchUsers.length>0">
              <v-list class="pa-0 ma-n4">
                <v-list-item v-for="(item, index) in searchUsers" :key="index" link @click="userCreate(item.id)">
                  <v-list-item-title class="py-2">
                    <h3 class="font-weight-regular mb-2">{{item.lastName}} {{item.firstName}} {{item.secondName}}</h3>
                    <div class="font-weight-regular">
                      <span class="mr-4"><v-icon class="mr-2" small>mdi-account-outline</v-icon>{{item.userProfile.gender==='male' ? 'Мужчина' : 'Женщина'}}</span>
                      <span class="mr-4"><v-icon class="mr-2" small>mdi-calendar-star</v-icon>{{item.userProfile.birth|date}}</span>
                      <span><v-icon class="mr-2" small>mdi-phone-outline</v-icon>+{{item.phone}}</span>
                    </div>
                    <div v-if="!$tools.isEmpty(item.userProfile.workOrgName)" class="font-weight-regular mt-1">
                      <span class="mr-4"><v-icon class="mr-2" small>mdi-office-building</v-icon>{{item.userProfile.workOrgName}}</span>
                      <span v-if="!$tools.isEmpty(item.userProfile.workOrgPhone)"><v-icon class="mr-2" small>mdi-phone-outline</v-icon>+{{item.userProfile.workOrgPhone}}</span>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
            <div v-else>
              <alert-view icon="mdi-information-outline" text="Не найдено" />
            </div>
          </div>
        </div>
        <v-form v-show="mainState.step===1 && mainState.isInit" ref="userCreateForm" class="pt-3">
          <v-row>
            <v-col cols="12">
              <!-- <v-text-field v-model="userCreatePhone" label="Телефон" :rules="formFieldRules.required" outlined dense hide-details="auto" v-mask="'+7 (###) ###-##-##'"/> -->
              <phone-field :phone.sync="userCreatePhone" label="Телефон" required/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="userCreateLastName" label="Фамилия" :rules="formFieldRules.required" outlined dense hide-details="auto" @keyup="$tools.firstLatterUppercase($event)"/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="userCreateFirstName" label="Имя" :rules="formFieldRules.required" outlined dense hide-details="auto" @keyup="$tools.firstLatterUppercase($event)"/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="userCreateSecondName" label="Отчество" :rules="formFieldRules.required" outlined dense hide-details="auto" @keyup="$tools.firstLatterUppercase($event)"/>
            </v-col>
            <v-col cols="12">
              <v-select v-model="userCreateGender" :items="[{name:'Женщина', value:'female'},{name:'Мужчина', value:'male'}]" item-text="name" item-value="value" label="Пол" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <!-- <v-text-field v-model="userCreateBirth" label="Дата рождения" :rules="formFieldRules.required" outlined dense hide-details="auto"/> -->
              <simple-date-view :date.sync="userCreateBirth" title="Дата рождения" required/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="userProfileWorkOrgName" label="Название организации" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="userProfileWorkOrgPhone" label="Телефон организации" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-select v-model="userCreatePromoSource" :rules="formFieldRules.required" :items="promoSources" :loading="promoSourcesState.isLoading" item-text="name" item-value="id" label="Рекламный источник" outlined dense hide-details="auto"/>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <div v-if="mainState.step===0">
          <div v-if="mainState.isInit">
            <v-btn text @click="search()" color="primary">Поиск</v-btn>
          </div>
          <div v-if="mainState.isSuccess">
            <v-btn
                v-if="!userSearchMatch"
                @click="
                  mainState.stepNext();
                  mainState.stateInit();
                  promoSourcesLoad();
                  userCreateLastName = searchLastName?.trim();
                  userCreateFirstName = searchFirstName?.trim();
                  userCreateSecondName = searchSecondName?.trim();
                  userCreateGender = 'female';
                  userCreateBirth = null;
                  userCreatePhone = searchPhone;
                  userProfileWorkOrgName = '';
                  userProfileWorkOrgPhone = '';
                  $refs.userCreateForm.resetValidation()
                "
                class="mr-3" text color="primary"
            >
              Создать
            </v-btn>
            <btn-question v-if="userSearchMatch" @click="userCreate(null,true)" :loading="mainState.isLoading"  color="primary" text  size="default" class="mr-2" title="Создать нового клиента?">
            <template v-slot:default>Создать</template>
            <template v-slot:content>
                <div class="mx-5 my-5">
                  <v-alert
                    outlined
                    type="warning"
                    prominent
                    border="left"
                  >
                  Было найдено совпадение, Вы уверены, что хотите создать нового пользователя?
                  </v-alert>
                </div>
            </template>
          </btn-question>
          </div>
        </div>
        <div v-if="mainState.step===1">
          <v-btn text @click="userCreate()" :loading="mainState.isLoading" color="primary">Сохранить</v-btn>
        </div>
      </template>
    </dialog-view>
    <dialog-view
        :open="deleteState.isModalOpen"
        title="Удалить представителя?"
        @cancel="deleteState.modalClose()"
    >
      <template v-slot:actions>
        <v-btn text @click="deleteUserForVisit()" :loading="deleteState.isLoading" color="primary">Удалить</v-btn>
      </template>
    </dialog-view>
  </div>
</template>

<script>

import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import {FormFieldRules} from "@/plugins/formFieldRules";
import moment from "moment";
import AlertView from "@/components/AlertView";
import DialogView from "@/components/DialogView";
import SimpleDateView from "@/components/SimpleDateView";
import CallPhone from "@/components/CallPhone.vue";
import PhoneField from "@/components/PhoneField";
import BtnQuestion from "@/components/btnQuestion.vue";

export default {
  components: {CallPhone, DialogView, AlertView, SimpleDateView, PhoneField, BtnQuestion},
  props: ['visitId','user'],
  data: () => ({
    formFieldRules: FormFieldRules,

    mainState: new State(),
    deleteState: new State(),

    userCreateLastName:null,
    userCreateFirstName:null,
    userCreateSecondName:null,
    userCreateGender:null,
    userCreateBirth:null,
    userCreatePhone:null,
    userProfileWorkOrgName:null,
    userProfileWorkOrgPhone:null,

    userCreatePromoSource:undefined,
    promoSourcesState: new State(),
    promoSources:[],

    searchPhone:null,
    searchFirstName:null,
    searchLastName:null,
    searchSecondName:null,
    searchUsers:[],
    userSearchMatch:false
  }),
  computed: {
    birth: {
      get() {
        if(this.user===null) return '';
        return moment(this.user.userProfile.birth, 'YYYY-MM-DD').format('DD.MM.YYYY');
      }
    }
  },
  methods: {
    promoSourcesLoad(){
      this.promoSourcesState.stateLoading();
      axios.get(Api.host+'/user/promoSource/')
          .then(r=> {
            this.promoSources = r.data;
            this.promoSourcesState.stateSuccess();
          })
          .catch(()=>{
            this.promoSourcesState.stateError();
          });
    },

    search(createNewUserParams){
      this.mainState.stateLoading();

      this.userSearchMatch = false;
      //если не переданы параметры из формы создания нового клиента
      let params = {};
      if (!createNewUserParams) {
        if(!this.$tools.isEmpty(this.searchPhone))
          if(this.searchPhone.length>2) params.phone = this.searchPhone;
        if(!this.$tools.isEmpty(this.searchFirstName)) params.firstName = this.searchFirstName?.trim();
        if(!this.$tools.isEmpty(this.searchLastName)) params.lastName = this.searchLastName?.trim();
        if(!this.$tools.isEmpty(this.searchSecondName)) params.secondName = this.searchSecondName?.trim();
      } else {
        params = createNewUserParams;
      }
      axios.get(Api.host+'/user/?filters='+JSON.stringify(params)+'&sortBy=lastName').then((r)=> {
        this.searchUsers = r.data.data;
        this.mainState.stateSuccess();

        //если переданы параметры из формы создания нового клиента
        if(createNewUserParams&&this.searchUsers.length>0) {
          this.userSearchMatch = true;
          this.mainState.step = 0;
        } else if (createNewUserParams&&this.searchUsers.length===0) {
          this.userCreate(null,true);
        }
      })
      .catch(()=>{
        this.mainState.stateError();
      });
    },

    userCreate(userId=null,force = false){
      if(userId===null){
        if (this.$refs.userCreateForm.validate()) {
          if (!force) {
            //Ищем еще раз перед созданием клиента по ФИО
            let params = {};
            params.firstName = this.userCreateFirstName?.trim();
            params.lastName = this.userCreateLastName?.trim();
            params.secondName = this.userCreateSecondName?.trim();

            this.search(params);
          }
          if (this.searchUsers.lenght===0||force===true) {
            this.mainState.stateLoading();
            let userBirth = null;
            if(this.userCreateBirth) {
              userBirth = moment(this.userCreateBirth).format('YYYY-MM-DD')
            }
            axios.post(Api.host+'/visit/'+this.visitId+'/repUser/', {
              userPhone: this.userCreatePhone,
              userFirstName: this.userCreateFirstName?.trim(),
              userLastName: this.userCreateLastName?.trim(),
              userSecondName: this.userCreateSecondName?.trim(),
              userProfileGender: this.userCreateGender,
              userProfileBirth: userBirth,
              userProfilePromoSource: this.userCreatePromoSource,
              userProfileWorkOrgName: this.userProfileWorkOrgName,
              userProfileWorkOrgPhone: this.userProfileWorkOrgPhone,
            })
              .then((r)=> {
                this.mainState.modalClose();
                this.$emit('created', r.data);
              })
              .catch(()=>{
                this.mainState.stateError();
              });
          }  
        }
      } else {
        this.mainState.stateLoading();
        axios.post(Api.host+'/visit/'+this.visitId+'/repUser/', {
          userId: userId
        })
            .then((r)=> {
              this.mainState.modalClose();
              this.$emit('created', r.data);
            })
            .catch(function (error) {
              this.mainState.stateError();
              console.log(error)
            });
      }
    },

    deleteUserForVisit(){
      this.deleteState.stateLoading();
      axios.delete(Api.host+'/visit/'+this.visitId+'/repUser/')
          .then(() =>{
            this.deleteState.stateSuccess();
            this.deleteState.modalClose();
            this.$emit('delete');
          })
          .catch(e =>{
            Api.responseError(e)
            this.deleteState.stateError();
          });
    },

    close(){
      if(this.mainState.step===0) {
        if(this.mainState.isInit)
          this.mainState.modalClose();
        else
          this.mainState.stateInit();
      } else if(this.mainState.step>0){
        if(this.mainState.isInit){
          this.mainState.stateSuccess();
          this.mainState.stepPrev();
        } else {
          this.mainState.stateInit();
        }
      }
      this.userSearchMatch = false;
    }
  }
}
</script>