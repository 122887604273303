<template>
  <v-sheet class="position-relative" min-height="48" color="transparent">
    <div
        v-if="loadState.isLoading && typeof disableLoader==='undefined'"
        class="position-absolute"
        style="z-index:1000;top:0;left:0;right:0;bottom:0"
    >
      <v-progress-circular
          class="position-relative mx-auto d-block"
          color="primary"
          indeterminate
          size="30"
          style="z-index:1000;top:50%;left:50%;margin-left:-15px!important;margin-top:-15px!important"
      />
    </div>
    <slot v-if="data!==null" :data="data" name="content"/>
  </v-sheet>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";

export default {
  props: ['query', 'queryVars','disableLoader'],
  data: () => ({
    loadState: new State(),
    data: null,
    queryVarsCurrent:null,
  }),
  mounted(){
    this.load();
  },
  watch:{
    queryVars(){
      this.load();
    }
  },
  methods:{
    load(force=false){
      let a = JSON.stringify(this.queryVars);
      if(a!==JSON.stringify(this.queryVarsCurrent) || force) {
        this.queryVarsCurrent = JSON.parse(a);
        this.loadState.stateLoading();
        Api.graphql(this.query, this.queryVars, (data) => {
          this.loadState.stateSuccess();
          this.data = data;
          this.$emit('loaded', data);
        }, (e) => {
          this.loadState.stateError(e)
          this.data = null;
        });
      }
    },
  }
}
</script>
