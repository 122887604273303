<template>
  <tooltip right @open="(v)=>v ? load() : null" openOnClick>
    <template v-slot:default>
      <v-btn small icon class="ml-2 mt-n1">
        <v-icon small>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <progress-page v-if="loadState.isLoading"/>
      <div v-if="loadState.isSuccess">
        <v-expansion-panels accordion>
          <v-expansion-panel v-if="!$tools.isEmpty(item.info.med)">
            <v-expansion-panel-header class="px-2">Медицинская информация</v-expansion-panel-header>
            <v-expansion-panel-content class="mx-n4">{{item.info.med}}</v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!$tools.isEmpty(item.info.executor)">
            <v-expansion-panel-header class="px-2">Для исполнителя</v-expansion-panel-header>
            <v-expansion-panel-content class="mx-n4">{{item.info.executor}}</v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!$tools.isEmpty(item.info.client)">
            <v-expansion-panel-header class="px-2">Для клиента</v-expansion-panel-header>
            <v-expansion-panel-content class="mx-n4">{{item.info.client}}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </tooltip>
</template>

<script>
import axios from "axios";
import Tooltip from "@/componentsV2/base/Tooltip.vue";
import ProgressPage from "@/components/ProgressPage.vue";
import State from "@/plugins/state";
export default {
  components: {ProgressPage, Tooltip},
  props:[
    'labId',
    'code',
    'name'
  ],
  data:()=>({
    loadState: new State(),
    item:null,
  }),
  methods:{
    load(){
      this.loadState.stateLoading();
      axios.get('/napi/lab/'+this.labId+'/item/search/?code='+encodeURI(this.code)+'&name='+encodeURI(this.name))
          .then(r =>{
            if(r.data.length>0)
              this.item = r.data[0];
            this.loadState.stateSuccess();
          })
          .catch(() =>{
            this.loadState.stateError();
          });
    }
  }
}
</script>