<template>
  <div style="margin-bottom: 16px">
    <div class="caption mb-1">{{ name }}</div>
    <div class="position-relative body-2">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name']
}
</script>
