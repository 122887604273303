<template>
<div>
  <v-text-field
      v-if="serviceDiscountType === '₽'"
      v-model.number="localDiscount"
      @input="discountCalc()"
      type="text" background-color="white" hide-details outlined dense class="_inside-input-wrapper" ondragstart="return false;" @focus="$event.target.setSelectionRange(0,5)"
      ref="input"
      >
    <template v-slot:append>
      <v-select
          :items="moneyOrPercent"
          v-model="serviceDiscountType"
          hide-details dense
          @change="refreshDiscount();"
      ></v-select>
    </template>
  </v-text-field>
  <v-text-field
      v-else
      v-model.number="localDiscount"
      @input="discountCalc()"
      type="text" background-color="white" hide-details outlined dense class="_inside-input-wrapper" ondragstart="return false;" @focus="$event.target.setSelectionRange(0,5)"
      ref="input">
    <template v-slot:append>
      <v-select
          :items="moneyOrPercent"
          v-model="serviceDiscountType"
          hide-details dense
          @change="refreshDiscount();"
      ></v-select>
    </template>
  </v-text-field>
</div>
</template>

<script>

export default {
  props: [
    'price', 'discount', 'count'
  ],
  data:()=>({
    serviceDiscountType: '%',
    moneyOrPercent: ['₽','%'],
    localDiscount:0,
    throttle:null
  }),
  watch:{
    discount(){
      if(this.discount !== '') {
        this.load();
      } else {
        this.refreshDiscount();
      }
    }
  },
  mounted(){
    this.load();
  },
  methods: {
    load() {
      if(this.discount) {
        if (this.serviceDiscountType === '%') {
          if (this.price){
            this.localDiscount = parseInt(this.price)>0 ? (this.discount/((this.price/100)*this.count)).toFixed(0) : 0;
          } else {
            this.localDiscount = this.discount;
            this.discountCalc();
          }
        } else {
          this.localDiscount = this.discount.toFixed(0);

          this.discountCalc();
        }
      }
    },
    discountCalc(){
      clearTimeout(this.throttle);
      this.throttle = setTimeout(()=>{
        let obj = {
          type: this.serviceDiscountType,
          discount: this.localDiscount
        }
        if (typeof this.price !== 'undefined') {
          if (this.serviceDiscountType === '%') {
              let discount = (this.localDiscount*((this.price/100)*this.count)).toFixed(0);
              this.$emit('update:discount', discount);
              this.$emit('action',obj);
          } else {
            this.$emit('update:discount', this.localDiscount);
            this.$emit('action',obj);
          }
        } else {
          this.$emit('action',obj);
        }
      }, 500);  
    },
    refreshDiscount() {
      this.localDiscount = 0;
      let obj = {
        type: this.serviceDiscountType,
        discount: this.localDiscount
      }
      this.$emit('update:discount', this.localDiscount);
      this.$emit('action',obj);

      setTimeout(()=>{
        const textFieldElement = this.$refs.input.$refs.input;
        if (textFieldElement) {
          textFieldElement.setSelectionRange(0,5);
        }
      },300);
    }
  }
}
</script>