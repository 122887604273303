<template>
  <v-sheet height="40" rounded outlined @click="localValue=!localValue" class="clickable">
    <v-row no-gutters>
      <v-col cols="auto">
        <v-checkbox :value="localValue" color="primary" class="pa-0 ml-2 mb-2" style="margin-top:7px" dense hide-details />
      </v-col>
      <v-col cols="auto" align-self="center" class="pr-3 body-2">
        {{name}}
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: ['name', 'value'],
  data: () => ({
    localValue: null,
  }),
  mounted() {
    this.localValue = this.value;
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
    localValue() {
      this.$emit('update:value', this.localValue)
    }
  },
  computed:{
    localValueError:{
      get(){
        if(typeof this.required!=='undefined' && this.$tools.isEmpty(this.localValue))
          return ['Поле обязательное'];

        if(this.$tools.isEmpty(this.format) || this.$tools.isEmpty(this.localValue))
          return [];

        if(new RegExp(this.format).test(this.localValue))
          return [];

        return ['Неверный формат'+(this.$tools.isEmpty(this.formatExample) ? '' : '. Пример: '+this.formatExample)];
      },
      set(){
      }
    }
  }
}
</script>