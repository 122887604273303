<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="count"
        :loading="loading"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [50, 100, 500, 1000, 5000]}"
        :items-per-page="50"
        dense
        :height="height-(16*6)"
        :fixed-header="typeof height!=='undefined'"
        multi-sort
        :show-select="typeof selectAll!=='undefined'"
        :class="(typeof tableClass==='undefined' ? '' : ' '+tableClass)"
        :sort-by="typeof sortBy !=='undefined' ? sortBy : null"
        ref="nTable"
    >
      <template v-slot:header.data-table-select="">
        <v-checkbox
            v-if="typeof selectAll!=='undefined'"
            :input-value="selectAll"
            @change="$emit('update:selectAll', !selectAll)"
            :indeterminate="selectIndeterminate"
            class="ma-0 pa-0" dense hide-details />
      </template>
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-row no-gutters class="d-none d-lg-flex">
          <v-col>
            <v-toolbar v-if="typeof name!=='undefined'" class="bar" dense elevation="0" color="transparent" max-height="48px">
              <v-toolbar-title class="body-2 font-weight-medium">{{ name }}</v-toolbar-title>
              <v-spacer/>
              <div style="margin-right:-160px">
                <slot name="barActions"/>
              </div>
            </v-toolbar>
          </v-col>
          <v-col cols="auto">
            <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                :itemsPerPageOptions="[50, 100, 500, 1000, 5000]"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                class="ml-0"
                />
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <slot name="item" v-bind:item="item" v-bind:items="items" v-bind:headers="headers"/>
      </template>
      <template v-slot:footer.prepend>
        <v-btn small text @click="$tools.download($refs,name)" :disabled="count===0" class="mr-2" :loading="loading">
          <v-icon small class="pr-2">mdi-download</v-icon>Скачать
        </v-btn>
        <v-spacer/>
      </template>
    </v-data-table>
  </div>
</template>
<script>

export default {
  props: [
    'name',
    'height',
    'headers',
    'selectAll',
    'selectIndeterminate',
    'tableClass',
    'genRequestBuilder',
    'sortBy'
  ],
  data: () => ({
    options:{},
    items:[],
    count:0,
    loading:false,
  }),
  watch: {
    options: {
      handler () {
        this.load(true)
      },
    }
  },
  mounted() {
    this.load(true);
  },
  methods:{
    load(fast=false){
      this.$tools.throttle(()=>{
        let sorts = [];
        this.options.sortBy.forEach((v, k)=>{
          sorts.push({
            field: v,
            isAsc: this.options.sortDesc[k]
          });
        });

        let requestBuilder = this.genRequestBuilder(
            this.options.page,
            this.options.itemsPerPage,
            sorts
        );

        //sync options
        this.options.page = requestBuilder._page
        this.options.itemsPerPage = requestBuilder._onPage

        requestBuilder.addStateCallback((v)=>{
          this.loading = v.loading;
          this.isSuccess = v.success;
          this.isError = v.error;
          this.errorMessage = v.errorMessage;
          if(this.isSuccess){
            this.items = v.items;
            this.count = v.count;
            
            //return to the first page if items is empty and it isn't first page
            if(v.items.length===0&&this.options.page>1){
              this.options.page = 1;
            }
          }
 
        })
        

        requestBuilder.request(null,(e)=>{
            this.$store.commit('alertError', e);
        })
      }, fast ? 100 : 1000, 'nTableView')
    },
  }
}
</script>

