<template>
  <div v-if="serviceCatRootsState.isLoading" class="full-width">
    <progress-page />
  </div>
  <div v-else-if="serviceCatRootsState.isSuccess && serviceCatRoots.length>0">
    <v-sheet min-height="40">
      <v-breadcrumbs class="px-3 py-2">
        <div v-if="breadItems.length===0">
          <v-breadcrumbs-item class="caption font-weight-medium " @click="serviceCatOpen('root')">
            Главные категории
          </v-breadcrumbs-item>
        </div>
        <div v-for="(bread, i) in breadItems" :key="i">
          <v-breadcrumbs-item :class="'caption font-weight-medium '+(i<breadItems.length-1 ? 'grey--text clickable' : '')" @click="(i<breadItems.length-1 ? serviceCatOpen(bread.id) : null)">
            <v-avatar v-if="bread.comBranch!==null" class="mr-2" :color="bread.comBranch.color" size="10" style="margin-top:-1px"/>
            <chip v-if="bread.isProgram===true" small dark color="green">Программа</chip>
            <span :class="bread.isProgram===true ? 'ml-2': ''">{{bread.text}}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider v-if="i<breadItems.length-1">/</v-breadcrumbs-divider>
        </div>
      </v-breadcrumbs>
    </v-sheet>
    <v-data-table
        class="tableMultipleSelect"
        dense
        :headers="headers"
        :items="(comBranchSelect.length>0 ? serviceCatOpenDataItems.filter(v=>v.comBranch===null || typeof v.comBranch==='undefined' || comBranchSelect.some(v1 =>v.comBranch.id===v1.id)) : serviceCatOpenDataItems)"
        :loading="serviceCatOpenState.isLoading"
        :footer-props="{'items-per-page-options': [1000]}"
        :options.sync="options"
        :items-per-page="1000"
        sort-by="sort"
        show-select
        fixed-header
        :height="typeof height==='undefined' ? null : height-40"
        hide-default-footer
    >
      <template v-slot:header.data-table-select>
        <v-checkbox v-model="selectedAll" :indeterminate="selectedAllIndeterminate" class="ma-0 pa-0" dense hide-details />
      </template>
      <template v-slot:item="{ item }">
        <tr v-if="item.id==='back'" @click="serviceCatOpen(item.serviceCatId)" class="clickable">
          <td colspan="1"></td>
          <td colspan="9">
            <chip color="green" dark>
              <v-icon small color="white">mdi-arrow-left</v-icon>
            </chip>
          </td>
        </tr>
        <tr
            v-else-if="typeof item.isProgram!=='undefined'"
            @click="serviceCatOpen(item.id)"
            class="clickable"
        >
          <td></td>
          <td>{{item.id}}</td>
          <td>
            <v-icon small class="mr-2">mdi-folder-outline</v-icon>
            <tooltip-view v-if="!$tools.isEmpty(item.comBranch)" top :content="item.comBranch.name">
              <template v-slot:action>
                <v-avatar class="mr-2" :color="item.comBranch.color" size="10" style="margin-top:-1px"/>
              </template>
            </tooltip-view>
            <chip v-if="item.isProgram" color="green" dark small class="mr-2">Программа</chip>
            <span>{{item.name}}</span>
          </td>
          <td>{{item.serviceCatServiceCount}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-no-wrap text-right">{{ serviceCatIsInProgram() || item.isProgram ? $tools.price(item.price) : ''}}</td>
        </tr>
        <tr
            v-else
            class="clickable"
            @click.stop="selectedAddRemove(item)"
        >
          <td>
            <v-checkbox @click.stop="selectedAddRemove(item)" :input-value="isSelected(item)" class="ma-0 pa-0" dense hide-details />
          </td>
          <td>{{item.id}}</td>
          <td>
            <tooltip-view v-if="!$tools.isEmpty(item.comBranch)" top :content="item.comBranch.name">
              <template v-slot:action>
                <v-avatar class="mr-2" :color="item.comBranch.color" size="10" style="margin-top:-1px"/>
              </template>
            </tooltip-view>
            <tooltip-view v-if="item.type==='serviceComposite'" top content="Составная услуга">
              <template v-slot:action>
                <v-icon class="mr-2" size="18" style="margin-top:-1px">mdi-label-multiple-outline</v-icon>
              </template>
            </tooltip-view>
            <tooltip-view v-if="item.type==='product'" top content="Товар">
              <template v-slot:action>
                <v-icon class="mr-2" size="16">mdi-basket-outline</v-icon>
              </template>
            </tooltip-view>
            {{item.name}}
          </td>
          <td></td>
          <td>{{item.isid}}</td>
          <td>
            <service-code-view :code="item.code"/>
          </td>
          <td>
              <span v-html="formatLab(item.serviceLabItem)"></span>
          </td>
          <td class="text-right">{{item.duration}} мин.</td>
          <td class="text-right">{{item.resultDuration}} д.</td>
          <td class="text-no-wrap text-right">{{$tools.price(item.priceTotal)}}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import axios from "axios";
import TooltipView from "@/components/TooltipView";
import ServiceCodeView from "@/views/Service/ServiceCodeView";
import Chip from "@/componentsV2/base/Chip.vue";
import ProgressPage from "@/components/ProgressPage.vue";

export default {
  components: {ProgressPage, ServiceCodeView, TooltipView, Chip},
  props: ['selectedItems','comBranchSelect','height'],
  data: () => ({
    menuIndex:0,

    serviceCatRootsState: new State(),
    serviceCatRoots: [],

    serviceCatOpenState: new State(),
    serviceCatOpenData:null,
    serviceCatOpenDataItems:[],

    breadItems: [],
    headers: [
      { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap', sortable: false},
      { text: 'Название', value: 'name', class:'text-no-wrap', sortable: false },
      { text: 'Элементов', value: 'count', class:'text-no-wrap', sortable: false },
      { text: 'Внеш. код', value: 'code', class:'text-no-wrap', sortable: false },
      { text: 'Код', value: 'code', class:'text-no-wrap', sortable: false },
      { text: 'Лаборатория', value: 'serviceLabItem', class:'text-no-wrap' },
      { text: 'Длительность', value: 'duration', class:'text-no-wrap', sortable: false },
      { text: 'Срок исполнения', value: 'resultDuration', class:'text-no-wrap', sortable: false },
      { text: 'Цена', value: 'price', class:'text-no-wrap', sortable: false },
    ],

    selected:[],
    options: {},
  }),
  mounted() {
    this.serviceCatRootsState.stateLoading();
    this.selected = this.selectedItems
    axios.get(Api.host+'/serviceCat/struct/')
        .then(r =>{
          this.serviceCatRoots = r.data.filter(serviceCat=>serviceCat.isShowForOrder);
          this.serviceCatOpenDataItems = this.serviceCatRoots
          this.serviceCatRootsState.stateSuccess();
        })
        .catch(e =>{
          Api.responseError(e)
          this.serviceCatRootsState.stateError();
        });
  },
  watch:{
    selectedItems(){
      this.selected = this.selectedItems;
    }
  },
  computed:{
    selectedAll:{
      get() {
        return this.serviceCatOpenDataItems.length>0 && this.serviceCatOpenDataItems.length===this.selected.length;
      },
      set(v) {
        this.selected = v ? this.serviceCatOpenDataItems.filter(v=>v.id!=='back' && typeof v.serviceCatServiceCount =='undefined') : [];
        this.$emit('update:selectedItems',this.selected);
      }
    },
    selectedAllIndeterminate:{
      get() {
        return this.selected.length>0 && this.serviceCatOpenDataItems.length!==this.selected.length;
      },
      set() {
        this.selected = this.items.filter(v=>v.id!=='back' && typeof v.serviceCatServiceCount =='undefined');
        this.$emit('update:selectedItems',this.selected);
      }
    },
    formatLab(){
      return (serviceLabItem) =>{
        if (serviceLabItem.length>0) {
          let arr = [];
          serviceLabItem.forEach(l=>{
            arr.push(l.labId);
          })
          return arr.join(', ');
        } else {
          return '-';
        }
      }
    }
  },
  methods: {
    serviceCatOpen(id){
      if (id==='root'){
        this.serviceCatOpenDataItems = this.serviceCatRoots;
        this.breadItems = [];
      } else {
        this.serviceCatOpenState.stateLoading();
        axios.get(Api.host+'/serviceCat/'+id+'/struct/')
          .then(r =>{
            this.serviceCatOpenData = r.data;
            this.serviceCatOpenDataItems = [];

            if (this.serviceCatOpenData.serviceCat!=null) {
              this.serviceCatOpenDataItems.push({id:'back', serviceCatId:this.serviceCatOpenData.serviceCat.id});
            }
            else {
              this.serviceCatOpenDataItems.push({id:'back', serviceCatId:'root'});
            }

            this.serviceCatOpenData.serviceCats.forEach((v)=>{
              if(v.isShowForOrder)
              this.serviceCatOpenDataItems.push(v);
            });

            this.serviceCatOpenData.services.forEach((v)=>{
              this.serviceCatOpenDataItems.push(v);
            });


            let serviceCat = this.serviceCatOpenData;

            let menuIndex = null;
            this.serviceCatRoots.forEach((v,index)=>{
              if(v.id===serviceCat.id) menuIndex=index;
              if(serviceCat.serviceCat!==null){
                if(v.id===serviceCat.serviceCat.id) menuIndex=index;
                if(serviceCat.serviceCat.serviceCat!==null){
                  if(v.id===serviceCat.serviceCat.serviceCat.id) menuIndex=index;
                  if(serviceCat.serviceCat.serviceCat.serviceCat!==null){
                    if(v.id===serviceCat.serviceCat.serviceCat.serviceCat.id) menuIndex=index;
                    if(serviceCat.serviceCat.serviceCat.serviceCat.serviceCat!==null){
                      if(v.id===serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.id) menuIndex=index;
                    }
                  }
                }
              }
            });
            if(menuIndex!==null)
              this.menuIndex = menuIndex;

            this.breadItems = [];
            if (serviceCat.serviceCat!=null){
              if (serviceCat.serviceCat.serviceCat!=null){
                if (serviceCat.serviceCat.serviceCat.serviceCat!=null){
                  if (serviceCat.serviceCat.serviceCat.serviceCat.serviceCat!=null){
                    if (serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat!=null){
                      this.breadItems.push({
                        id:serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.id,
                        text: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name,
                        isProgram: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                        comBranch: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                        disabled: false,
                      });
                    }
                    this.breadItems.push({
                      id:serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.id,
                      text: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name,
                      isProgram: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                      comBranch: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                      disabled: false,
                    });
                  }
                  this.breadItems.push({
                    id:serviceCat.serviceCat.serviceCat.serviceCat.id,
                    text: serviceCat.serviceCat.serviceCat.serviceCat.name,
                    isProgram: serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                    comBranch: serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                    disabled: false,
                  });
                }
                this.breadItems.push({
                  id:serviceCat.serviceCat.serviceCat.id,
                  text: serviceCat.serviceCat.serviceCat.name,
                  isProgram: serviceCat.serviceCat.serviceCat.isProgram,
                  comBranch: serviceCat.serviceCat.serviceCat.comBranch,
                  disabled: false,
                });
              }
              this.breadItems.push({
                id:serviceCat.serviceCat.id,
                text: serviceCat.serviceCat.name,
                isProgram: serviceCat.serviceCat.isProgram,
                comBranch: serviceCat.serviceCat.comBranch,
                disabled: false,
              });
            }
            this.breadItems.push({
              id:serviceCat.id,
              text: serviceCat.name,
              isProgram: serviceCat.isProgram,
              comBranch: serviceCat.comBranch,
              disabled: false,
            });

            this.serviceCatOpenState.stateSuccess();
          })
          .catch(e =>{
            Api.responseError(e)
            this.serviceCatOpenState.stateError();
          });
        }
    },

    serviceCatIsInProgram(){
      let result = this.breadItems.filter((v)=>v.isProgram);
      return result.length>0;
    },

    selectedAddRemove(item){
      if(!this.isSelected(item)){
        this.selected.push(item);
      } else {
        this.selected = this.selected.filter((v)=>{
          if(v.id===item.id && typeof v.isProgram===typeof item.isProgram)
            return false;
          return true;
        });
      }
      this.$emit('update:selectedItems',this.selected);
    },
    isSelected(item){
      let find = false;
      this.selected.forEach((v)=>{
        if(v.id===item.id && typeof v.isProgram===typeof item.isProgram)
          find = true;
      });
      return find;
    },
  }
}
</script>
