<template>
  <v-chip
      :color="status==='success' ? 'green' : status==='error' ? 'red' : 'grey'"
      small
  >
    <v-sheet
        :min-width="width-24"
        color="transparent"
        :class="typeof left==='undefined' ? 'text-center' : 'text-left'"
        dark>
      {{text}}
    </v-sheet>
  </v-chip>
</template>

<script>
export default {
  props: [
    'status',
    'text',
    'width',
    'left'
  ],
}
</script>