<template>
  <v-sheet outlined rounded>
    <div class="position-relative">
      <v-avatar v-if="active===true" class="position-absolute" color="white" size="24" style="right:-12px;top:-12px">
        <v-icon color="green" size="22">mdi-check-circle</v-icon>
      </v-avatar>
      <slot name="header"/>
    </div>
    <div class="pt-4 px-4 position-relative">
      <slot name="default"/>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props:['active'],
}
</script>