<template>
  <div>
    <v-data-table
        :headers="externalBody ? [] : headers"
        :items="items"
        :server-items-length="itemsTotal"
        :loading="loadState.isLoading"
        :options.sync="options"
        :footer-props="{'items-per-page-options': itemsPerPageOptions}"
        :class="'tableGraphql'+(typeof tableClass==='undefined' ? '' : ' '+tableClass)"
        dense
        :height="heightLocal"
        :fixed-header="typeof height!=='undefined'"
        ref="table"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-row no-gutters align="center">
          <v-col>
            <v-toolbar v-if="typeof title!=='undefined'" class="bar" dense elevation="0" color="transparent" max-height="48px">
              <v-toolbar-title class="body-2 font-weight-medium">{{ title }}</v-toolbar-title>
            </v-toolbar>
          </v-col>
          <v-col v-if="!!$slots.actions" cols="auto" class="pb-n1">
            <slot name="actions"/>
          </v-col>
          <v-col v-if="!!$slots.actions1" cols="auto">
            <slot name="actions1"/>
          </v-col>
          <v-col cols="auto" class="pl-4">
            <v-data-footer
                v-if="!externalBody"
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="itemsPerPageOptions"
                class="mr-0"
            />
          </v-col>
          <slot name="header" v-bind:items="items"/>
        </v-row>
      </template>

      <template v-if="externalBody" v-slot:body>
        <slot name="body" v-bind:items="items" v-bind:bodyHeight="heightLocal"/>
      </template>
      <template v-else v-slot:item="{ item }">
        <slot name="item" v-bind:item="item" v-bind:items="items"/>
      </template>
      <template v-slot:footer.prepend>
        <v-btn small text @click="download()" :disabled="itemsTotal===0" class="mr-2" :loading="loadState.isLoading">
          <v-icon small class="pr-2">mdi-download</v-icon>Скачать
        </v-btn>
        <div v-if="items.length>0 && typeof dataSizeAlert!=='undefined'">
          <v-chip v-if="allResults" color="green lighten-4">
            <v-icon class="mr-2" color="green" small>mdi-check-circle</v-icon>
            <div class="caption">Все результаты</div>
          </v-chip>
          <tooltip-view v-else top content="Увеличьте количество строк на странице что бы отобразить все результаты">
            <template v-slot:action>
              <v-chip color="red lighten-5">
                <v-icon class="mr-2" color="red" small>mdi-alert</v-icon>
                <div class="caption black--text">Не все результаты</div>
              </v-chip>
            </template>
          </tooltip-view>
        </div>
        <v-spacer/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import TooltipView from "@/components/TooltipView.vue";

export default {
  components: {TooltipView},
  props: [
    'title',
    'headers',
    'height',
    'barHeight',
    'footerHeight',
    'tableClass',
    'query',
    'queryVars',
    'queryMainEntity',
    'externalBody',
    'dataSizeAlert'
  ],
  data: () => ({
    loadState: new State(),
    queryVarsCurrent:null,
    options:{},
    items: [],
    itemsTotal: 0,
    allResults:true,
    itemsPerPageOptions:[100,500,1000,3000]
  }),
  watch: {
    options() {
      this.load(true, false);
    }
  },
  computed: {
    barHeightLocal(){
      return typeof this.barHeight==='undefined' ? 48 : this.barHeight;
    },
    footerHeightLocal(){
      return typeof this.footerHeight==='undefined' ? 48 : this.footerHeight;
    },
    heightLocal(){
      return this.height-this.barHeightLocal-this.footerHeightLocal;
    }
  },
  methods:{
    load(force=false, toFirstPage=true){     
      this.$tools.throttle(()=>{
        if(toFirstPage)
          this.options.page = 1;
        this.queryVars.page = this.options.page;

  
        this.queryVars.onPage = this.options.itemsPerPage;
     

        if(this.queryVars.sortBy===null){
          if(typeof this.options.sortBy[0]=='undefined') {
            this.queryVars.sortBy = 'id';
            this.queryVars.sortDesc = true;
          } else {
            this.queryVars.sortBy = this.options.sortBy[0];
            this.queryVars.sortDesc = !this.options.sortDesc[0];
          }
        }

        let a = JSON.stringify(this.queryVars);
        if(a!==JSON.stringify(this.queryVarsCurrent) || force){
          this.queryVarsCurrent = JSON.parse(a);
          this.loadState.stateLoading();
          Api.graphql(this.query, this.queryVars, (data, total, sum)=>{
            this.loadState.stateSuccess()
            if(data[this.queryMainEntity]===null){
              this.items = [];
              this.itemsTotal = 0;
            } else {
              this.items = data[this.queryMainEntity];
              this.itemsTotal = total[this.queryMainEntity];
            }
            this.allResults = this.items.length===this.itemsTotal;

            this.$emit('loaded', this.items, this.itemsTotal, data, total, sum);

          }, (e)=>{
            this.loadState.stateError(e)
            this.$emit('loaded', [], 0);
          });
        }
      },100);

    },
    download(){
      let a = '\ufeff';
      let table = this.$refs.table.$el.querySelector('.v-data-table__wrapper table');
      table.querySelectorAll('thead th').forEach((th)=>{
        a +=th.querySelector('span').innerText+';'
      }) 
      a +=`
  `;
  table.querySelectorAll('tbody tr').forEach((tr)=>{
      tr.querySelectorAll('td').forEach((td)=>{
        if (!td.querySelector('.v-input--checkbox')) {//exclude checkbox
          let v = td.textContent.replace('\n', '. ');

          //content for print
          if (td.hasAttribute('data-print')) {
            v  = td.getAttribute('data-print');
          }

          //v = v.replace('-', ' ');
          v = v.replace(/"/g, '');
          v = v.replace(/;/g, '');
          v = v.replace(/\n/g, ' ');

          if(v.endsWith(" Р")){
            v = v.substring(0, v.length-2)
            v = v.replace(',', '');
            v = v.replace(/\./g, ',');
          }

          a +=v+';'
        }  
      })
      a +=`
`;
      })

      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(a));
      element.setAttribute('download', this.queryMainEntity+'.csv');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }
}
</script>
