<template>
  <v-form ref="form">
    <dialog-view
      :open="dataState.isModalOpen"
      :title="(dataState.modalData===null ? '' : typeof dataState.modalData?.id=='undefined' ? 'Создать ожидание визита' : 'Ожидание визита #'+dataState.modalData.id)"
      content-full-size @cancel="$emit('cancel')"
    >
      <template v-slot:barActions>
        <log v-if="dataState.modalData?.id" entity-name="VisitPlan" :entity-id="dataState.modalData.id"/>
      </template>
      <template v-slot:content v-if="dataState.modalData!==null">
        <div class="caption mx-4 mt-2">Клиент:</div>
        <div class="mx-4 mb-2">
          {{dataState.modalData.user.lastName=== null ? '': dataState.modalData.user.lastName}}
          {{dataState.modalData.user.firstName=== null ? '': dataState.modalData.user.firstName}}
          {{dataState.modalData.user.secondName=== null ? '': dataState.modalData.user.secondName}}
          <v-btn icon depressed :href="'/panel/user/client/'+dataState.modalData.user.id+'/'" target="_blank" small>
            <v-icon small>mdi-open-in-new</v-icon>
          </v-btn>
          <div><call-phone :phone="dataState.modalData.user.phone" v-if="dataState.modalData.user.phone" /></div>
        </div>
        <v-divider class="mb-2"/>
        <v-tabs v-model="userOrSpec" class="px-4">
          <v-tab>Специалист</v-tab>
          <v-tab-item class="pt-4">
            <v-autocomplete
                v-model="userSelectedId"
                :items="users"
                :search-input.sync="usersText"
                label="Специалист"
                outlined
                dense
                hide-details="auto"
                item-text="name"
                item-value="id"
                :rules="userOrSpec===0 ? formFieldRules.required :[]"
                class="mb-4"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Не найдено</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{item}">
                <v-list-item-avatar class="user"><img :src="item.imageUrl" v-if="item.imageUrl" alt=""></v-list-item-avatar>
                <v-list-item-content style="width:393px">
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                  <v-list-item-subtitle>
                    <div v-for="userSpec in item.userSpecs" :key="item.id+'_'+userSpec.id" class="text-wrap">
                      {{userSpec.spec.name}}
                      <v-icon small>mdi-chevron-left</v-icon>
                      {{userSpec.comDiv.comBranch.name}}
                      <v-icon v-if="!$tools.isEmpty(userSpec.commentAdmin)" class="ml-2 mr-1" small>mdi-comment-text-outline</v-icon>
                      {{userSpec.commentAdmin}}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{item}">
                <v-avatar class="mr-2 user" size="24">
                  <img :src="item.imageUrl" v-if="item.imageUrl" alt="">
                </v-avatar>
                <div class="mr-2">{{item.name}}</div>
              </template>
            </v-autocomplete>
            <v-select
                v-show="userSpecsState.isSuccess"
                v-model="userSpecSelectedId"
                label="Специализация"
                :items="userSpecs"
                item-value="id"
                item-text="name"
                :rules="userOrSpec===0 ? formFieldRules.required :[]"
                class="mb-4" outlined dense hide-details="auto"/>

             <div class="d-flex justify-center mb-3" v-if="userSpecsState.isLoading">   
                <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>    
            </div>  

            <v-alert
              outlined
              type="warning"
              prominent
              icon="mdi-exclamation"
              v-if="userOrSpec===0&&userSpecsState.isSuccess&&userSelectedId&&userSelectedEvents.length===0"
            >
              нет доступных дат
            </v-alert>
          </v-tab-item>
          <v-tab>Специальность</v-tab>
          <v-tab-item class="pt-4">
            <v-autocomplete
                v-model="specSelectedId"
                :items="specs"
                :search-input.sync="specText"
                label="Специальность"
                outlined
                dense
                hide-details="auto"
                item-text="name"
                item-value="id"
                :rules="userOrSpec===1 ? formFieldRules.required :[]"
                class="mb-4"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Не найдено</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{item}">
                <v-list-item-content>
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{item}">
                <div class="mr-2">{{item.name}}</div>
              </template>
            </v-autocomplete>
          </v-tab-item>
        </v-tabs>
        <v-divider class="mb-4"/>
        <v-menu v-if="!dataState.modalData.isLiveList" v-model="beginDateState.isModalOpen" :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-bind="attrs" v-on="on"
                v-model="beginDateState.modalData"
                :rules="formFieldRules.required"
                label="Дата" class="mx-4 mb-4" outlined dense hide-details="auto" readonly/>
          </template>
          <v-date-picker
              v-model="beginDateState.modalData1"
              @change="beginDateState.modalClose($tools.dateTimeFormat(beginDateState.modalData1, 'DD.MM.YYYY'), beginDateState.modalData1)"
              no-title
              :first-day-of-week="1"
              :width="393"
              :allowed-dates="userOrSpec===0 ? allowedDates : false"
              :events="userSelectedEvents"
              :event-color="date => userSelectedEventsColors[date]"
              color="primary"
          />
        </v-menu>
        <v-textarea v-model="dataState.modalData.comment" label="Комментарий" class="mx-4 mb-4" rows="1" auto-grow hide-details outlined dense/>

        <div class="mx-4 mb-4" v-if="dataState.modalData.id!==undefined">
          <v-btn block color="primary" depressed @click="visitCreate()">Создать визит</v-btn>
        </div>
      </template>
      <template v-slot:actions v-if="dataState.modalData!==null">
        <dialog-view
            :title="'Удалить ожидание визита #'+dataState.modalData.id+'?'"
            acceptName="Удалить"
            @acceptAction="deleteAction()"
            :loading="deleteState.isLoading"
            v-if="dataState.modalData.id!==undefined"
        >
          <template v-slot:activator>
            <v-btn text color="error" class="mr-2">Удалить</v-btn>
          </template>
        </dialog-view>
        <v-btn color="primary" text @click="createOrUpdate()" :loading="updateState.isLoading">Сохранить</v-btn>
      </template>
    </dialog-view>
  </v-form>
</template>


<script>

import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import DialogView from "@/components/DialogView";
import {FormFieldRules} from "@/plugins/formFieldRules";
import moment from "moment";
import CallPhone from "@/components/CallPhone.vue";
import Log from "@/views/Log/Log";
import {
  Entity_UserSpecPlan,
  Entity_UserSpec, 
  Entity_User, 
  Entity_Spec, 
  Entity_ComPlace, 
  Entity_ComRoom, 
  Entity_ComFloor, 
  Entity_ComBuilding, 
  Entity_ComBranch
} from "../../../EntityStoreCacheService";


export default {
  components: {DialogView, CallPhone, Log},
  props:['state','users','specs'],
  data: () => ({
    dataState: new State(),
    updateState: new State(),
    deleteState: new State(),
    userSpecsState: new State(),
    formFieldRules: FormFieldRules,

    userOrSpec:0,

    userSelectedId:null,
    usersText:null,

    userSpecSelectedId:null,
    userSpecs:[],

    beginDateState:new State(),
    beginTime:null,

    specSelectedId:null,
    specText:null,
    userSelectedEvents: [],
    userSelectedEventsColors: {},
    userSelectedEventsSpecs: {},
    alreadyCreated:false
  }),
  watch:{
    state: {
      deep: true,
      handler() {
        if(this.state.isModalOpen){
          this.$refs.form.resetValidation();
          this.dataState.modalOpen(JSON.parse(JSON.stringify(this.state.modalData)));
          if(typeof this.state.modalData.id==='undefined'){
            this.userOrSpec = 0;
            this.userSelectedId = null;
            this.userSpecSelectedId = null;
            this.specSelectedId = null;
          } else {
            this.beginDateState.modalData = this.$tools.dateTimeFormat(this.dataState.modalData.begin, 'DD.MM.YYYY');
            this.beginDateState.modalData1 = this.$tools.dateTimeFormat(this.dataState.modalData.begin, 'YYYY-MM-DD');

            if(this.dataState.modalData.userSpec===null){
              this.userOrSpec = 1;
              this.userSelectedId = null;
              this.specSelectedId = this.dataState.modalData.spec.id;
            } else {
              this.userOrSpec = 0;
              this.specSelectedId = null; 
              this.userSelectedId = this.dataState.modalData.userSpec.user.id;              
            }           
          }

          if (typeof this.dataState.modalData.id !=='undefined') {
            this.alreadyCreated = true;
          }
        } else{
          this.dataState.isModalOpen=false;
          this.userOrSpec = 0;
          this.userSelectedId = null;
          this.userSpecSelectedId = null;
          this.specSelectedId = null;
          this.beginDateState.modalData = null;
          this.alreadyCreated = false
        }
      },
    },
    userSpecsState: {
      deep: true,
      handler(v) {
        if (v.isSuccess)
          this.checkAvailableSpec(this.beginDateState.modalData)
      }    
    },
    userSelectedId(v){
      if (v!==null) {
        this.specSelectedId = null
        this.getUserSelectedEventsAndSpecs();
      }  
    },
    specSelectedId(v){
      if (v!==null) {
        this.userSelectedId = null
        this.userSpecSelectedId = null
        this.getUserSelectedEventsAndSpecs();
      }  
    },
    'beginDateState.modalData'(newDate) {
      if (this.userSpecsState.isSuccess)
        this.checkAvailableSpec(newDate)
    }
  },
  methods:{
    allowedDates(val){
      if (this.userSelectedEvents.includes(val)) return val
    },
    visitCreate(){
      this.$emit('visitCreate', this.dataState.modalData)
    },
    deleteAction(){
      this.deleteState.stateLoading();
      axios.delete(Api.host+'/visitPlan/'+this.dataState.modalData.id+'/')
        .then(() =>{
          this.deleteState.stateSuccess();
          this.$emit('createdUpdatedDeleted');
        })
        .catch(e =>{
          this.deleteState.stateError();
          Api.responseError(e)
        });
    },
    createOrUpdate(){
      if (this.$refs.form.validate()) {
        let data = {};
        if(this.dataState.modalData.isLiveList){
          data.begin=moment().format('YYYY-MM-DD');
          data.isLiveList=true;
        } else {
          data.begin=moment(this.beginDateState.modalData+' '+this.beginTime+':00', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD');
        }
        if(this.userSpecSelectedId!==null) data.userSpec = this.userSpecSelectedId;
        if(this.specSelectedId!==null) data.spec = this.specSelectedId;
        if(!this.$tools.isEmpty(this.dataState.modalData.comment)) data.comment = this.dataState.modalData.comment;

        this.updateState.stateLoading();
        if(typeof this.dataState.modalData.id==='undefined'){
          data.user = this.dataState.modalData.user.id;
          axios.post(Api.host+'/visitPlan/', data)
              .then(()=> {
                this.updateState.stateSuccess();
                this.$store.commit('alertSuccess', 'Ожидание визита создано');
                this.$emit('createdUpdatedDeleted');
              })
              .catch((error)=> {
                this.updateState.stateError();
                console.log(error)
              });
        } else {
          axios.put(Api.host+'/visitPlan/'+this.dataState.modalData.id+'/', data)
              .then(()=> {
                this.updateState.stateSuccess();
                this.$store.commit('alertSuccess', 'Ожидание визита обновлено');
                this.$emit('createdUpdatedDeleted');
              })
              .catch((error)=> {
                this.updateState.stateError();
                console.log(error)
              });
        }
      }
    },
    checkAvailableSpec(date) {
      let selectedDate = moment(date,'DD.MM.YYYY').format('YYYY-MM-DD')
      let activeSpecIndex = null
      this.userSpecSelectedId = null
      if (this.dataState.modalData.isLiveList) {
        selectedDate = moment().format('YYYY-MM-DD')
      }
      let day = this.userSelectedEventsSpecs[selectedDate]

      this.userSpecs = this.users.filter(v=>v.id===this.userSelectedId)[0]?.userSpecs;
      if(typeof this.userSpecs !=='undefined'&&this.userSpecs.length>0) {
        this.userSpecs.forEach((v)=>{
          v.name = v.spec.name+' < '+v.comDiv.comBranch.name;
        });
        //отключаем для выбора недоступные специализации
        this.userSpecs.forEach((spec,index) => {
           if (typeof day==='undefined'||!day.includes(spec.id)) {
            spec.disabled = true
          } else {
            spec.disabled = false
            if(activeSpecIndex === null)
              activeSpecIndex = index
          }
        });
        //Выбор специальности, 
        //если загружаем созданный, то выбирается ранее выбранная специализация, 
        //если новый, то первая доступная специальность в этот день
        if (activeSpecIndex !== null&&!this.alreadyCreated)
          this.userSpecSelectedId = this.userSpecs[activeSpecIndex].id
        if (this.alreadyCreated&&this.dataState.modalData?.userSpec?.id) 
          this.userSpecSelectedId = this.dataState.modalData.userSpec.id
          this.alreadyCreated = false
      }
    },
    getUserSelectedEventsAndSpecs() {
      this.userSelectedEvents = [];
      this.userSelectedEventsColors = {};
      this.userSelectedEventsSpecs = {};

      if (this.userSelectedId||this.specSelectedId) {
        this.userSpecsState.stateLoading();
        let builder = new Entity_UserSpecPlan()
        .selects(Entity_UserSpecPlan.id)
        .selects(Entity_UserSpecPlan.begin)
        .selects(Entity_UserSpecPlan.type)
        .selects(Entity_UserSpecPlan.status)
        .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.id].join('.'))
        .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user,Entity_User.id].join('.'))
        .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.spec, Entity_Spec.id].join('.'))
        .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.comRoom,Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.id].join('.'))
        .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.comRoom,Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.color].join('.'))
        
        if (!this.dataState.modalData.isLiveList) {
          builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.begin].join('.')+'>=?',moment(this.daySelected).format('YYYY-MM-DD 00:00:00'));
        } else {
          builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.begin].join('.')+'>=?',moment().format('YYYY-MM-DD 00:00:00'));
        }
        builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.type].join('.')+'=?','workVisit');
        builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.status].join('.')+'=?','active');

   
        if (this.userSelectedId) {
          builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.userSpec, Entity_UserSpec.user,Entity_User.id].join('.')+'=?',this.userSelectedId);
        }

        if(this.specSelectedId) {
          builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.userSpec, Entity_UserSpec.spec, Entity_Spec.id].join('.')+'=?',this.specSelectedId);
        }
 
        builder.page(1).onPage(3000);

        builder.request((data)=>{
          if (data.count>0) {
            data.items.forEach(uSP=>{
              this.userSelectedEvents.push(this.$tools.dateTimeFormat(uSP.begin, 'YYYY-MM-DD'));

              let key = this.$tools.dateTimeFormat(uSP.begin, 'YYYY-MM-DD');
              let color = uSP.comPlace.comRoom.comFloor.comBuilding.comBranch.color;
              let spec = uSP.userSpec.id
 
              //colors
              if (typeof this.userSelectedEventsColors[key] === 'undefined')
                this.userSelectedEventsColors[key] = []
              if (!this.userSelectedEventsColors[key].includes(color)&&this.userSelectedEventsColors[key].length<3)
                this.userSelectedEventsColors[key].push(color)            
              //specs
              if (typeof this.userSelectedEventsSpecs[key] === 'undefined')
                this.userSelectedEventsSpecs[key] = []
              if (!this.userSelectedEventsSpecs[key].includes(spec))
                this.userSelectedEventsSpecs[key].push(spec)              
            });
          }

          this.userSpecsState.stateSuccess();
        }, (e)=>{
          console.error(e.response.data.message);
          this.userSpecsState.stateError();
        })
      }
     
    },
  }
}
</script>