<template>
  <autocomplete-multiple :name="directionCat===null ? 'Направления' : 'Направления ('+directionCat.name+')'" :items="items" :select.sync="localSelect" :loading="loading" backgroundColor="white">
    <template v-slot:selectedInfo="{item}">
      <div>{{item.directionCat.name}}: {{item.name}}</div>
    </template>
    <template v-slot:itemInfo="{item}">
      <div class="caption">{{item.directionCat.name}}</div>
    </template>
    <template v-slot:append>
      <slot name="append"/>
    </template>
    <template v-slot:appendOuter>
      <tooltip-view v-if="typeof directionCatId!=='undefined'" bottom class="ml-2">
        <template v-slot:action>
          <v-icon>mdi-information-outline</v-icon>
        </template>
        <template v-slot:content>
          <div class="px-2 pb-2">
            <div v-if="directionCat!==null">
              <div class="caption mr-1">Не входит в "{{directionCat.name}}":</div>
              <div v-for="(v,k) in localSelectAll" :key="k" class="caption mr-1">{{v.name}}</div>
            </div>
            <v-btn v-if="localDirectionCatId!==null" depressed small class="mt-2" @click="directionCat=null;localDirectionCatId=null;load()">Снять ограничение</v-btn>
            <v-btn v-else depressed small class="mt-2" @click="directionCat=null;localDirectionCatId=directionCatId;load()">Включить ограничение</v-btn>
          </div>
        </template>
      </tooltip-view>
    </template>
  </autocomplete-multiple>
</template>

<script>
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";
import {
  Entity_Direction, Entity_DirectionCat
} from "../../../EntityStoreCacheService";
import TooltipView from "@/components/TooltipView.vue";

export default {
  components: {TooltipView, AutocompleteMultiple},
  props:[
    'select',
    'directionCatId',
    'categoryValidation'
  ],
  data:()=>({
    items: [],
    localDirectionCatId: null,
    localSelect: null,
    localSelectAll: [],
    loading:false,
    directionCat:null,
  }),
  watch: {
    select() {
      let select = typeof this.categoryValidation !=='undefined' ? this.directionCategoryValidation(this.select) : this.select

      if(this.localDirectionCatId===null){
        this.localSelect = select;
      } else {
        this.localSelectAll = select.filter(v=>parseInt(v.directionCat.id)!==this.localDirectionCatId);
        this.localSelect = select.filter(v=>parseInt(v.directionCat.id)===this.localDirectionCatId);
      }
    },
    localSelect() {
      if(this.localDirectionCatId===null) {
        this.$emit('update:select', this.localSelect)
      } else {
        let localSelectAll = this.$tools.copy(this.localSelectAll)
        this.localSelect.forEach(v => {
          localSelectAll.push(v);
        })
        
        if(!this.$tools.equals(localSelectAll, this.select))
          this.$emit('update:select', localSelectAll)
      }
    }
  },
  mounted() {
    this.localDirectionCatId = typeof this.directionCatId==='undefined' ? null : this.directionCatId;
    this.load();
  },
  methods:{
    load(){
      if(this.localDirectionCatId===null){
        this.localSelect = this.select;
      } else {
        this.localSelectAll = this.select.filter(v=>parseInt(v.directionCat.id)!==this.localDirectionCatId);
        this.localSelect = this.select.filter(v=>parseInt(v.directionCat.id)===this.localDirectionCatId);
      }

      let rb = new Entity_Direction()
          .selects(Entity_Direction.id)
          .selects(Entity_Direction.name)
          .selects([Entity_Direction.directionCat, Entity_DirectionCat.id].join('.'))
          .selects([Entity_Direction.directionCat, Entity_DirectionCat.name].join('.'))
          .order([Entity_Direction.directionCat, Entity_DirectionCat.name].join('.'), true)
          .page(1)
          .onPage(1000)
          .addStateCallback((r)=>{
            this.loading = r.loading;
            if(r.success){
              r.items.forEach(v1=>{
                if(this.items.filter(v2=>v1.id===v2.id).length===0)
                  this.items.push(v1);
              });
              if(this.localDirectionCatId!==null)
                this.items = this.items.filter(v=>parseInt(v.directionCat.id)===this.localDirectionCatId)
            }
          });
      if(this.localDirectionCatId===null) {
        rb.request();
      } else {
        new Entity_DirectionCat()
            .selects(Entity_DirectionCat.id)
            .selects(Entity_DirectionCat.name)
            .filterAnd([Entity_DirectionCat.entity, Entity_DirectionCat.id].join('.')+'=?',this.localDirectionCatId)
            .page(1)
            .onPage(1)
            .addStateCallback((r)=>{
              if(r.success) {
                this.directionCat = r.items[0];
                rb
                    .selects([Entity_Direction.directionCat, Entity_DirectionCat.id].join('.'))
                    .selects([Entity_Direction.directionCat, Entity_DirectionCat.name].join('.'))
                    .filterAnd([Entity_Direction.entity, Entity_Direction.directionCat, Entity_DirectionCat.id].join('.')+'=?',this.directionCat.id)
                rb.request();
              }
            })
            .request();
      }
    },
    directionCategoryValidation(selectedItems) {
      let filteredSelect = selectedItems

      if (selectedItems.length>1) {

        const lastItemCatId = parseInt(selectedItems.at(-1).directionCat.id)
        const previousSelect = [...selectedItems]
        const lastAddedElem = previousSelect.pop()

        for (let i = 0; i < previousSelect.length; i++) {
            let catId = parseInt(selectedItems[i].directionCat.id);

            if (lastItemCatId===catId) {

              previousSelect[i] = lastAddedElem

              filteredSelect = previousSelect

              this.$store.commit('alertError','Можно выбрать только 1 направление из каждой категории');
              break;
            }
        }
      }
      
      return filteredSelect
    }
  }
}
</script>