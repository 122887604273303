<template>
  <v-row class="align-center ma-0">
    <v-col cols="12" class="pb-1">
      <v-breadcrumbs class="pa-0 text-no-wrap">
        <v-breadcrumbs-item v-if="item.value.parent!=null && item.value.parent.parent!=null" class="caption">
          <div class="grey--text float-left mr-1 caption font-weight-medium rounded-small">{{item.value.parent.parent.code}}</div>
          <span>{{item.value.parent.parent.name}}</span>
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider v-if="item.value.parent!=null" class="caption px-1">/</v-breadcrumbs-divider>
        <v-breadcrumbs-item v-if="item.value.parent!=null" class="caption">
          <div class="grey--text float-left mr-1 caption font-weight-medium rounded-small">{{item.value.parent.code}}</div>
          <span>{{item.value.parent.name}}</span>
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </v-col>
    <v-col cols="auto" class="pt-0">
      <chip>
        <v-sheet min-width="90" color="transparent">{{item.value.code}}</v-sheet>
      </chip>
    </v-col>
    <v-col class="pt-0 pl-0">
      <div class="d-inline-block body-2 font-weight-medium">{{item.value.name}}</div>
      <v-menu v-if="!$tools.isEmpty(item.value.additional_info)" open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small icon v-bind="attrs" v-on="on" class="mt-n1 position-absolute">
            <v-icon small>mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <v-card max-width="500">
          <div class="py-2 px-4 body-2" v-html="item.value.additional_info"></div>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import Chip from "@/componentsV2/base/Chip.vue";

export default {
  components: {Chip},
  props:['item']
}
</script>