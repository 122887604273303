var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-view',{attrs:{"open":_vm.open,"title":"Список платежей","big":"","content-full-size":""},on:{"cancel":function($event){_vm.open=false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('graphql-table-view',{ref:"table",attrs:{"query":"\n        query Query(\n          $visitIds: String\n        ){\n          Pay(\n            visitIds: $visitIds\n          ){\n            id\n            type\n            amount\n            amountPayed\n            amountCash\n            amountCard\n            amountBankAccount\n            amountAccount\n            amountOms\n            amountDms\n            amountContract\n            accountBalance\n            kktName\n            terminalName\n            tranId\n            comment\n            created\n            isAcceptBankAccount\n            isAcceptOms\n            isAcceptDms\n            isAcceptContract\n            user{\n              id\n              name\n            }\n            operatorUser{\n              id\n              name\n              type\n            }\n          }\n        }\n      ","queryVars":_vm.queryVars,"queryMainEntity":"Pay","headers":[
        { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap'},
        { text: 'Тип платежа', value: 'type', class:'text-no-wrap', sortable: false },
        { text: 'Способы расчета', value: 'source', class:'text-no-wrap', sortable: false },
        { text: 'Комментарий', value: 'comment', class:'text-no-wrap', sortable: false },
        { text: 'Кассир', value: 'operatorUser', class:'text-no-wrap', sortable: false },
        { text: 'Клиент', value: 'user', class:'text-no-wrap', sortable: false },
        { text: 'Фискальный регистратор', value: 'cashBox', class:'text-no-wrap', sortable: false },
        { text: 'Баланс личного счета', sortable: false, class:'text-no-wrap text-right'},
        { text: 'Сумма', value: 'amount', class:'text-no-wrap', sortable: false },
        { text: 'Дата', value: 'created', class:'text-no-wrap', sortable: false }
      ],"table-class":"pays"},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('tr',{on:{"click":function($event){_vm.payId=item.id}}},[_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.id))]),_c('td',{staticClass:"text-no-wrap"},[_c('v-icon',{staticClass:"ml-n1 mr-2",attrs:{"size":"18","color":_vm.types[item.type].color}},[_vm._v(_vm._s(_vm.types[item.type].icon))]),_vm._v(" "+_vm._s(_vm.types[item.type].name)+" ")],1),_c('td',{staticClass:"text-no-wrap"},[_c('pay-sources',{attrs:{"pay":item,"compact":""}})],1),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.comment))]),_c('td',{staticClass:"text-no-wrap"},[_c('user-button-view',{attrs:{"user":item.operatorUser,"type":item.operatorUser.type,"link":""}})],1),_c('td',{staticClass:"text-no-wrap"},[_c('user-button-view',{attrs:{"user":item.user,"type":"client","link":""}})],1),_c('td',{staticClass:"text-no-wrap"},[_c('pay-devices',{attrs:{"pay":{kktName:item.kktName}}})],1),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.type==="internalIn" || item.type==="internalOut" ? '' : _vm.$tools.price(item.accountBalance)))]),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$tools.price(item.amount)))]),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$tools.dateTime(item.created)))])])]}}])}),_c('pay1',{attrs:{"id":_vm.payId},on:{"cancel":function($event){_vm.payId=null}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }