<template>
  <dialog-view :open="open" title="Список платежей" big content-full-size @cancel="open=false">
    <template v-slot:content>
      <graphql-table-view
        query='
          query Query(
            $visitIds: String
          ){
            Pay(
              visitIds: $visitIds
            ){
              id
              type
              amount
              amountPayed
              amountCash
              amountCard
              amountBankAccount
              amountAccount
              amountOms
              amountDms
              amountContract
              accountBalance
              kktName
              terminalName
              tranId
              comment
              created
              isAcceptBankAccount
              isAcceptOms
              isAcceptDms
              isAcceptContract
              user{
                id
                name
              }
              operatorUser{
                id
                name
                type
              }
            }
          }
        '
        :queryVars="queryVars"
        queryMainEntity="Pay"
        :headers="[
          { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap'},
          { text: 'Тип платежа', value: 'type', class:'text-no-wrap', sortable: false },
          { text: 'Способы расчета', value: 'source', class:'text-no-wrap', sortable: false },
          { text: 'Комментарий', value: 'comment', class:'text-no-wrap', sortable: false },
          { text: 'Кассир', value: 'operatorUser', class:'text-no-wrap', sortable: false },
          { text: 'Клиент', value: 'user', class:'text-no-wrap', sortable: false },
          { text: 'Фискальный регистратор', value: 'cashBox', class:'text-no-wrap', sortable: false },
          { text: 'Баланс личного счета', sortable: false, class:'text-no-wrap text-right'},
          { text: 'Сумма', value: 'amount', class:'text-no-wrap', sortable: false },
          { text: 'Дата', value: 'created', class:'text-no-wrap', sortable: false }
        ]"
        table-class="pays"
        ref="table"
        >
          <template v-slot:item="{item}">
            <tr @click="payId=item.id">
              <td class="text-no-wrap">{{item.id}}</td>
              <td class="text-no-wrap">
                  <v-icon size="18" class="ml-n1 mr-2" :color="types[item.type].color">{{types[item.type].icon}}</v-icon>
                  {{types[item.type].name}}
              </td>
              <td class="text-no-wrap">
                  <pay-sources :pay="item" compact/>
              </td>
              <td class="text-no-wrap">{{item.comment}}</td>
              <td class="text-no-wrap"><user-button-view :user="item.operatorUser" :type="item.operatorUser.type" link/></td>
              <td class="text-no-wrap"><user-button-view :user="item.user" type="client" link/></td>
              <td class="text-no-wrap">
                  <pay-devices :pay="{kktName:item.kktName}"/>
              </td>
              <td class="text-no-wrap">{{item.type==="internalIn" || item.type==="internalOut" ? '' : $tools.price(item.accountBalance)}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amount)}}</td>
              <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
            </tr>
          </template>
        </graphql-table-view>
      <pay1 :id="payId" @cancel="payId=null"/>
    </template>
  </dialog-view>
</template>

<script>

import DialogView from "@/components/DialogView";
import PayDevices from "@/views/Pay/components/PayDevices.vue";
import UserButtonView from "@/components/UserButtonView.vue";
import PaySources from "@/views/Pay/components/PaySources.vue";
import GraphqlTableView from "@/components/GraphqlTableView.vue";
import Api from "@/Api";

export default {
  components: {
    GraphqlTableView, PaySources, UserButtonView, PayDevices,
    Pay1: () => import('@/views/Pay/Pay'),
    DialogView
  },
  props: ['visitId'],
  data: () => ({
    open: false,
    payId:null,
    types:Api.model.Pay.types,
    queryVars:null
  }),
  watch: {
    visitId() {
      if (this.visitId!=null){
        this.open = true;
        this.queryVars = {visitIds:this.visitId+','};
        this.$nextTick(()=>{
          if (!this.$refs.table.loadState.isLoading)
            this.$refs.table.load(true);
        });
      }
    },
    open(){
      if(!this.open){
        this.queryVars = null;
        this.$emit('close', null);
      }
    },
    payId(){
      if(this.payId===null)
        this.$nextTick(()=>{
            this.$refs.table.load(true);
        });
    }
  },
}
</script>
