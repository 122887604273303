<template>
  <div class="d-flex justify-center">
    <v-progress-circular
      v-if="typeof loadState !=='undefined'&&loadState.isLoading"
      class="justify-center"
      indeterminate
      color="primary"
    ></v-progress-circular> 
    <v-row v-else>
      <v-col cols="6">
        <v-text-field
            :maxlength="5"
            v-model="start"
            :value="begin"
            label="С"
            :rules="[rules.required, rules.counter]"
            @keyup="$tools.timeFormatter($event)"
            @focus="$event.target.setSelectionRange(0,5)"
            outlined dense hide-details="auto"
            ondragstart="return false;"/>
      </v-col>
      <v-col cols="6">
        <v-text-field
            :maxlength="5"
            v-model="finish"
            :value="end"
            label="По"
            :rules="[rules.required, rules.counter]"
            @keyup="$tools.timeFormatter($event)"
            @focus="$event.target.setSelectionRange(0,5)"
            outlined dense hide-details="auto"
            ondragstart="return false;">
          <template v-slot:append>
            <div class="caption text-no-wrap" style="margin-top:2px">{{diffTime}} мин</div>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn-toggle v-model="diffToggle" dense>
          <template  v-for="t in newTimeArr" >
          <v-btn v-if="newTimeArr" icon :value="String(t)" :key="t">{{t}}м</v-btn>
          </template>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </div>      
</template>

<script>
import moment from "moment";
export default {
  props: [
    'loadState',
    'begin',
    'end',
    'dayTimeBegin',
    'dayTimeEnd',
  ],
  data:()=>({
    start: null,
    finish: null,
    diffTime:null,
    diffToggle:null,
    timeArr: [5,15,30,45,60,90,120],
    newTimeArr:[],
    rules: {
      required: value => !!value || 'Поле обязательно',
      counter: value => String(value).length == 5
    },
  }),
  watch:{
    begin() {
      if(String(this.begin).length===5 && String(this.begin).indexOf(':')===2){
        this.start = this.begin
      }
    },
    end(){
      if(String(this.end).length===5 && String(this.end).indexOf(':')===2){
        this.finish = this.end
      }
    },
    start() {
      if (this.dayTimeBegin) {
        let begin = this.start
        if(String(begin).length===5 && String(begin).indexOf(':')===2){
          let diffInitial = moment(this.end, 'HH:mm').diff(moment(this.begin, 'HH:mm'), 'minutes'); 
          let diffTime = moment(this.finish, 'HH:mm').diff(moment(begin, 'HH:mm'), 'minutes');
          let diffEndDay = moment(begin, 'HH:mm').add(diffInitial, 'minutes').diff(moment(this.dayTimeEnd, 'HH:mm'), 'minutes');

          //time offset
          if (diffTime<=0&&diffEndDay<0){           
            this.finish = moment(this.start,'HH:mm').add(Math.abs(diffInitial), 'minutes').format('HH:mm')
          }

          if (moment(this.start, 'HH:mm').diff(moment(this.dayTimeBegin, 'HH:mm'), 'minutes')<0) {
            this.start = this.dayTimeBegin
          }

          this.timeValidation()

        } 
      } else {
        this.$emit('update:begin', this.start);
      }
    },
    finish() {
      if (this.dayTimeEnd) {
        let end = this.finish
        if(end !== null &&String(end).length===5 && String(end).indexOf(':')===2){

          if (moment(this.dayTimeEnd , 'HH:mm').diff(moment(this.finish, 'HH:mm'), 'minutes')<0) {
              this.finish = this.dayTimeEnd
          }


          this.timeValidation()
        } 
      }  
      else {
        this.$emit('update:end', this.finish); 
      }
    },
    diffToggle(){
      this.finish = moment(this.start,'HH:mm').add(+this.diffToggle, 'minutes').format('HH:mm')
    },
    dayTimeBegin() {
      this.btnValidation();
    }
  },
  mounted() {
      this.start = this.begin
      this.finish = this.end
      
      this.newTimeArr = this.timeArr
      this.timeValidation()
      this.updateDiffToggle()
      this.btnValidation()
      
  },
  methods: {
    calculateDiffTime() {
      this.diffTime = moment(this.finish, 'HH:mm').diff(moment(this.start, 'HH:mm'), 'minutes');
    },
    updateDiffToggle() {
      if (!isNaN(this.diffTime)&&this.diffTime>=0) {
        this.diffToggle = String(this.diffTime)
      }
    },
    btnValidation() {
      this.newTimeArr = []
      let dTe = this.dayTimeEnd

      this.timeArr.forEach(t=>{
        let diffTime = moment(dTe, 'HH:mm').diff(moment(this.start, 'HH:mm').add(t, 'minutes'), 'minutes');
        if (diffTime>=0) {
          this.newTimeArr.push(+t)
        }
      })
      this.calculateDiffTime()
      this.updateDiffToggle()
    },
    timeValidation(){
      //begin validate ||this.begin == this.start
      if(moment(this.start, 'HH:mm').isBetween(moment(this.dayTimeBegin, 'HH:mm').subtract(1, 'minutes'),moment(this.finish, 'HH:mm'))) {
        this.$emit('update:begin', this.start);
        
      } else {
        this.start = this.begin
        //this.$store.commit('alertError', 'Выберите другое время');
      }

      //if end time wrong at once 
      if(!moment(this.end, 'HH:mm').isBetween(moment(this.start, 'HH:mm'),moment(this.dayTimeEnd, 'HH:mm').add(1, 'minutes'))) {
        this.$emit('update:end', this.dayTimeEnd);
      }

      //if you type finish time 
      if(moment(this.finish, 'HH:mm').isBetween(moment(this.start, 'HH:mm'),moment(this.dayTimeEnd, 'HH:mm').add(1, 'minutes'))) {
        this.$emit('update:end', this.finish);       
      } else {
        this.finish = this.end
      }


      this.calculateDiffTime()
      this.updateDiffToggle()
      this.btnValidation()
    }
  }
}
</script>


<style lang="scss" scoped>
  .v-btn--active {
    pointer-events: none;
  }
</style>