import axios from "axios";

export default class Kkm {
  host = "http://localhost:5894";
  User = "User"; // Пользователь доступа к серверу торгового оборудования
  Password = ""; // Пароль доступа к серверу торгового оборудования

  execute(data, success=null, error=null) {
    function S4() {return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);}
    data.Timeout=60*15;
    data.IdCommand = (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());

    let complete = (r)=>{
      if(data.Command!=='List')
        axios.post('/api/log/',{
          entityName:'kkmServer',
          data:[data, r.data]
        });

      if(r.data.Error.length>0){
        if(error!==null)
          error(r.data.Error);
      } else {
        if(success!==null)
          success(r.data);
      }
    };

    axios.post(this.host+'/Execute',data)
      .then((r)=>{
        if(r.data.Error==='Message: Коллекция была изменена после создания экземпляра перечислителя.'){

          setTimeout(()=>{
            data.IdCommand = (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
            axios.post(this.host+'/Execute',data)
              .then(complete)
              .catch(() =>{
                if(error!==null) error("Ошибка подключения к ККМ серверу");
              });
          },1000);

        } else {
          complete(r);
        }
      })
      .catch(() =>{
        if(error!==null) error("Ошибка подключения к ККМ серверу");
      });
  }

  //DEVICES
  devices(success, error){
    this.execute({
      Command:"List"
    }, function(res){
      let arr = [];
      res.ListUnit.forEach(v=>{
        arr.push({
          id:v.NumDevice,
          name:v.UnitName==="" ? v.NameDevice : v.UnitName,
          typeName:v.TypeDevice,
        });
      });
      success(arr);
    }, error);
  }
  openShift(device, operatorName, operatorVatin, success, error){
    this.execute({
      Command: "OpenShift",
      NumDevice:device.id,
      CashierName: operatorName,
      CashierVATIN: operatorVatin,
    }, function(res){
      success(res);
    }, error);
  }
  deviceStatus(device, success, error){
    this.execute({
      Command:"GetDataKKT",
      NumDevice:device.id,
    }, function(res){
      let message = '';
      message += 'Заводской номер: '+res.Info.KktNumber+'\n';
      message += 'Номер ФН: '+res.Info.FnNumber+'\n';
      message += 'Регистрационный номер ККТ: '+res.Info.RegNumber+'\n';
      message += 'Адрес установки: '+res.Info.AddressSettle+'\n';

      //Статус сессии 1-Закрыта, 2-Открыта, 3-Открыта, но закончилась (3 статус на старых ККМ может быть не опознан)
      if(res.Info.SessionState===1) message += 'Смена: Закрыта'+'\n';
      if(res.Info.SessionState===2) message += 'Смена: Открыта'+'\n';
      if(res.Info.SessionState===3) message += 'Смена: Открыта, превышено время работы'+'\n';
      success(message);
    }, error);
  }
  deviceStatusSessionError(device, success, error){
    this.execute({
      Command:"GetDataKKT",
      NumDevice:device.id,
    }, function(res){
      success(res.Info.SessionState===3 ? 'Превышено время смены' : null);
    }, error);
  }

  //PAY
  payRegister(device, operatorName, operatorVatin, amountPayedCash, amountPayedCard, items, success=null, error=null){
    let arr = [];
    items.forEach(v=>{
      arr.push({
        Register: {
          // Наименование товара 64 символа, Тег 1059
          Name: v.name,
          // Количество товара (3 знака после запятой), Тег 1023
          Quantity: v.count,
          // Цена за шт. без скидки (2 знака после запятой)
          Price: v.price,
          // Конечная сумма строки с учетом всех скидок /наценок; (2 знака после запятой), Из нее расчет тега 1079
          Amount: v.priceTotal,
          // Отдел, по которому ведется продажа
          //'Department:0,
          // НДС в процентах или ТЕГ НДС=>0 (НДС 0%), 10 (НДС 10%), 20 (НДС 20%), -1 (НДС не облагается), 120 (НДС 20 /120), 110 (НДС 10 /110), Тег 1043, Из нее расчет тега 1079
          Tax: v.tax,
          // Признак способа расчета. Тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле
          // 1=>"ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)"
          // 2=>"ПРЕДОПЛАТА (Частичная предварительная оплата до момента передачи предмета расчета)"
          // 3=>"АВАНС"
          // 4=>"ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
          // 5=>"ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )"
          // 6=>"ПЕРЕДАЧА В КРЕДИТ (Передача предмета расчета без его оплаты в момент его передачи с последующей оплатой в кредит)"
          // 7=>"ОПЛАТА КРЕДИТА (Оплата предмета расчета после его передачи с оплатой в кредит )"
          SignMethodCalculation: v.type==='service' ? 4 : v.type==='product' ? 4 : v.type==='prepaid' ? 3 : 0,
          //ПРЕДОПЛАТА это аванс когда мы знаем за что клиент платит, АВАНС когда незнаем
          // Признак предмета расчета. Тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
          // 1=>"ТОВАР (наименование и иные сведения, описывающие товар)"
          // 2=>"ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)"
          // 3=>"РАБОТА (наименование и иные сведения, описывающие работу)"
          // 4=>"УСЛУГА (наименование и иные сведения, описывающие услугу)"
          // 5=>"СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
          // 6=>"ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
          // 7=>"ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)"
          // 8=>"ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)"
          // 9=>"ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)"
          // 10=>"ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)"
          // 11=>"АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)"
          // 12=>"СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение"
          // 13=>"ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение"
          // 14=>"ИМУЩЕСТВЕННОЕ ПРАВО" (передача имущественных прав)
          // 15=>"ВНЕРЕАЛИЗАЦИОННЫЙ ДОХОД"
          // 16=>"СТРАХОВЫЕ ВЗНОСЫ" (суммы расходов, уменьшающих сумму налога (авансовых платежей) в соответствии с пунктом 3.1 статьи 346.21 Налогового кодекса Российской Федерации)
          // 17=>"ТОРГОВЫЙ СБОР" (суммы уплаченного торгового сбора)
          // 18=>"КУРОРТНЫЙ СБОР"
          // 19=>"ЗАЛОГ"
          // 20=>"РАСХОД" - суммы произведенных расходов в соответствии со статьей 346.16 Налогового кодекса Российской Федерации, уменьшающих доход
          // 21=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ ИП" или "ВЗНОСЫ НА ОПС ИП"
          // 22=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОПС"
          // 23=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ ИП" или "ВЗНОСЫ НА ОМС ИП"
          // 24=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОМС"
          // 25=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ СОЦИАЛЬНОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОСС"
          // 26=>"ПЛАТЕЖ КАЗИНО" прием и выплата денежных средств при осуществлении казино и залами игровых автоматов расчетов с использованием обменных знаков игорного заведения
          SignCalculationObject: v.type==='service' ? 4 : v.type==='product' ? 1 : v.type==='prepaid' ? 10 : null,
          // Единица измерения предмета расчета. Можно не указывать, Тег 1197
          //'MeasurementUnit:"пара" ,
          // Цифровой код страны происхождения товара в соответствии с Общероссийским классификатором стран мира 3 симв. Тег 1230
          //'CountryOfOrigin:"156" ,
          // Регистрационный номер таможенной декларации 32 симв. Тег 1231
          //'CustomsDeclaration:"54180656/1345865/3435625/23" ,
          // Сумма акциза с учетом копеек, включенная в стоимость предмета расчета Тег 1229
          //'ExciseAmount:null, // Иначе сумма акциза
          // КИЗ (контрольный идентификационный знак) товарной номенклатуры, Тег ОФД 1162 (честный знак), можно не указывать
          //Описание применимых ШК
          //'GoodCodeData:[
          // штрих-код маркировки товара со сканера (нужно настроить сканер так чтобы не проглатывал управляющие символы)
          // Поддерживаются ШК=>
          // Без идентификатора экземпляра товара=>EAN8, EAN13, ITF14
          // С идентификатором экземпляра товара=>GS1, ШК шуб, ШК табачной продукции., ЕГАИС-2, ЕГАИС-3
          //'BarCode:"0104300943734342212413195240818240640291ffd092MDEwNDMwMDk0MzczNDM",
          // Проверять содержит ли ШК кода маркировки идентификатор экземпляра товара (если вообще не указать - true)
          // Для некоторых товаров нужно передавать ШК EAN-13, тогда это поле устанавливайте в 'false'
          //'ContainsSerialNumber:true,
          // Все равно регистрировать чек даже если код маркировки не прошел проверку
          // Только для работы по ФФД 1.2
          //'AcceptOnBad:true
          //],
        }
      });
    });

    this.execute({
      NumDevice:device.id,
      Command:'RegisterCheck',
      // Продавец, Тег ОФД 1021
      CashierName:operatorName,
      // ИНН продавца Тег ОФД 1203
      CashierVATIN:operatorVatin,
      IsFiscalCheck:true,
      // Тип чека, Тег 1054;
      // 0 – продажа/приход;                                      10 – покупка/расход;
      // 1 – возврат продажи/прихода;                             11 - возврат покупки/расхода;
      // 2 – корректировка продажи/прихода;                       12 – корректировка покупки/расхода;
      // 3 – корректировка возврата продажи/прихода; (>=ФФД 1.1)  13 – корректировка возврата покупки/расхода; (>=ФФД 1.1)
      TypeCheck:0,

      // Строки чека
      CheckStrings:arr,
      // Наличная оплата (2 знака после запятой), Тег 1031
      Cash:amountPayedCash,
      // Сумма электронной оплаты (2 знака после запятой), Тег 1081
      ElectronicPayment:amountPayedCard,
      // Сумма из предоплаты (зачетом аванса) (2 знака после запятой), Тег 1215
      //AdvancePayment: 0,
      // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой), Тег 1217
      //CashProvision:0,
    }, success, error);
  }
  payRegisterCancel(device, operatorName, operatorVatin, amountPayedCash, amountPayedCard, items, success=null, error=null){

    let arr = [];
    items.forEach(v=>{
      arr.push({
        Register: {
          // Наименование товара 64 символа, Тег 1059
          Name: v.name,
          // Количество товара (3 знака после запятой), Тег 1023
          Quantity: v.count,
          // Цена за шт. без скидки (2 знака после запятой)
          Price: v.price,
          // Конечная сумма строки с учетом всех скидок /наценок; (2 знака после запятой), Из нее расчет тега 1079
          Amount: v.priceTotal,
          // Отдел, по которому ведется продажа
          //'Department:0,
          // НДС в процентах или ТЕГ НДС=>0 (НДС 0%), 10 (НДС 10%), 20 (НДС 20%), -1 (НДС не облагается), 120 (НДС 20 /120), 110 (НДС 10 /110), Тег 1043, Из нее расчет тега 1079
          Tax: v.tax,
          // Признак способа расчета. Тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле
          // 1=>"ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)"
          // 2=>"ПРЕДОПЛАТА (Частичная предварительная оплата до момента передачи предмета расчета)"
          // 3=>"АВАНС"
          // 4=>"ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
          // 5=>"ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )"
          // 6=>"ПЕРЕДАЧА В КРЕДИТ (Передача предмета расчета без его оплаты в момент его передачи с последующей оплатой в кредит)"
          // 7=>"ОПЛАТА КРЕДИТА (Оплата предмета расчета после его передачи с оплатой в кредит )"
          SignMethodCalculation: v.type==='service' ? 4 : v.type==='product' ? 4 : v.type==='prepaid' ? 3 : 0,
          // Признак предмета расчета. Тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
          // 1=>"ТОВАР (наименование и иные сведения, описывающие товар)"
          // 2=>"ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)"
          // 3=>"РАБОТА (наименование и иные сведения, описывающие работу)"
          // 4=>"УСЛУГА (наименование и иные сведения, описывающие услугу)"
          // 5=>"СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
          // 6=>"ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
          // 7=>"ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)"
          // 8=>"ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)"
          // 9=>"ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)"
          // 10=>"ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)"
          // 11=>"АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)"
          // 12=>"СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение"
          // 13=>"ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение"
          // 14=>"ИМУЩЕСТВЕННОЕ ПРАВО" (передача имущественных прав)
          // 15=>"ВНЕРЕАЛИЗАЦИОННЫЙ ДОХОД"
          // 16=>"СТРАХОВЫЕ ВЗНОСЫ" (суммы расходов, уменьшающих сумму налога (авансовых платежей) в соответствии с пунктом 3.1 статьи 346.21 Налогового кодекса Российской Федерации)
          // 17=>"ТОРГОВЫЙ СБОР" (суммы уплаченного торгового сбора)
          // 18=>"КУРОРТНЫЙ СБОР"
          // 19=>"ЗАЛОГ"
          // 20=>"РАСХОД" - суммы произведенных расходов в соответствии со статьей 346.16 Налогового кодекса Российской Федерации, уменьшающих доход
          // 21=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ ИП" или "ВЗНОСЫ НА ОПС ИП"
          // 22=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОПС"
          // 23=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ ИП" или "ВЗНОСЫ НА ОМС ИП"
          // 24=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОМС"
          // 25=>"ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ СОЦИАЛЬНОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОСС"
          // 26=>"ПЛАТЕЖ КАЗИНО" прием и выплата денежных средств при осуществлении казино и залами игровых автоматов расчетов с использованием обменных знаков игорного заведения
          SignCalculationObject: v.type==='service' ? 4 : v.type==='product' ? 1 : v.type==='prepaid' ? 10 : 0,
          // Единица измерения предмета расчета. Можно не указывать, Тег 1197
          //'MeasurementUnit:"пара" ,
          // Цифровой код страны происхождения товара в соответствии с Общероссийским классификатором стран мира 3 симв. Тег 1230
          //'CountryOfOrigin:"156" ,
          // Регистрационный номер таможенной декларации 32 симв. Тег 1231
          //'CustomsDeclaration:"54180656/1345865/3435625/23" ,
          // Сумма акциза с учетом копеек, включенная в стоимость предмета расчета Тег 1229
          //'ExciseAmount:null, // Иначе сумма акциза
          // КИЗ (контрольный идентификационный знак) товарной номенклатуры, Тег ОФД 1162 (честный знак), можно не указывать
          //Описание применимых ШК
          //'GoodCodeData:[
          // штрих-код маркировки товара со сканера (нужно настроить сканер так чтобы не проглатывал управляющие символы)
          // Поддерживаются ШК=>
          // Без идентификатора экземпляра товара=>EAN8, EAN13, ITF14
          // С идентификатором экземпляра товара=>GS1, ШК шуб, ШК табачной продукции., ЕГАИС-2, ЕГАИС-3
          //'BarCode:"0104300943734342212413195240818240640291ffd092MDEwNDMwMDk0MzczNDM",
          // Проверять содержит ли ШК кода маркировки идентификатор экземпляра товара (если вообще не указать - true)
          // Для некоторых товаров нужно передавать ШК EAN-13, тогда это поле устанавливайте в 'false'
          //'ContainsSerialNumber:true,
          // Все равно регистрировать чек даже если код маркировки не прошел проверку
          // Только для работы по ФФД 1.2
          //'AcceptOnBad:true
          //],
        }
      });
    });

    this.execute({
      NumDevice:device.id,
      Command:'RegisterCheck',
      // Продавец, Тег ОФД 1021
      CashierName:operatorName,
      // ИНН продавца Тег ОФД 1203
      CashierVATIN:operatorVatin,
      IsFiscalCheck:true,
      // Тип чека, Тег 1054;
      // 0 – продажа/приход;                                      10 – покупка/расход;
      // 1 – возврат продажи/прихода;                             11 - возврат покупки/расхода;
      // 2 – корректировка продажи/прихода;                       12 – корректировка покупки/расхода;
      // 3 – корректировка возврата продажи/прихода; (>=ФФД 1.1)  13 – корректировка возврата покупки/расхода; (>=ФФД 1.1)
      TypeCheck:1,

      // Строки чека
      CheckStrings:arr,
      // Наличная оплата (2 знака после запятой), Тег 1031
      Cash:amountPayedCash,
      // Сумма электронной оплаты (2 знака после запятой), Тег 1081
      ElectronicPayment:amountPayedCard,
      // Сумма из предоплаты (зачетом аванса) (2 знака после запятой), Тег 1215
      //AdvancePayment: 0,
      // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой), Тег 1217
      //CashProvision:0,
    }, success, error);
  }

  payCard(device, amount, success=null, error=null){
    this.execute({
      Command:"PayByPaymentCard",
      NumDevice:device.id,
      Amount:amount,
    }, function(res){
      success(res);
    }, error)
  }

  payCardCancel(device, amount, success=null, error=null){
    this.execute({
      Command:"ReturnPaymentByPaymentCard",//ReturnPaymentByPaymentCard - Вернуть платеж по платежной карте, CancelPaymentByPaymentCard - Отменить платеж
      NumDevice:device.id,
      Amount:amount,
    }, function(res){
      success(res);
    }, error)
  }

  //COMMAND
  xReport(device, success, error){
    this.execute({
      Command:"XReport",
      NumDevice:device.id,
    }, function(){
      success();
    }, error);
  }

  zReport(device, success, error){
    this.execute({
      Command:"ZReport",
      NumDevice:device.id,
    }, function(){
      success();
    }, error);
  }

  closeTerminalShift(device, success, error){
    this.execute({
      Command:"Settlement",
      NumDevice:device.id,
    }, function(res){
      success(res);
    }, error);
  }

  report(device, success, error){
    this.execute({
      Command:"TerminalReport",
      Detailed: true,
      NumDevice:device.id,
    }, function(res){
      success(res);
    }, error);
  }

}



