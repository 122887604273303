<template>
  <v-menu
      v-model="isOpen"
      :close-on-content-click="false"
      offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-bind="attrs"
          v-on="on"
          :value="$tools.date(date)"
          :label="title"
          :rules="typeof required==='undefined' ? [] : formFieldRules.required"
          clearable
          @click:clear="localDate=null"
          outlined dense hide-details readonly/>
    </template>
    <v-date-picker v-model="localDate" color="primary" show-current no-title :first-day-of-week="1" @change="isOpen=false"/>
  </v-menu>
</template>

<script>
import moment from "moment";
import {FormFieldRules} from "@/plugins/formFieldRules";

export default {
  props: [
    'title',
    'date',
    'required',
    'dbformat'
  ],
  data:()=>({
    formFieldRules: FormFieldRules,
    isOpen:false
  }),
  computed:{
    localDate:{
      get(){
        if(this.$tools.isEmpty(this.date))
          return moment().format('YYYY-MM-DD');
        return moment(this.date).format('YYYY-MM-DD');
      },
      set(v){
        if(v==null)
          this.$emit('update:date', null);
        else
          this.$emit('update:date', typeof this.dbformat==='undefined' ? moment(v).format() : moment(v).format('YYYY-MM-DD HH:mm:ss'));
      }
    }
  }
}
</script>