<template>
  <div v-if="!$tools.isEmpty(code)" class="d-inline-block">
      <chip
          :class="'clickable '+contentClass"
          @click="info"
      >
        <v-sheet min-width="90" color="transparent">{{code}}</v-sheet>
      </chip>
    <dialog-view
        :open="dataState.isModalOpen"
        :loading="dataState.isLoading"
        :title="'Код услуги: '+code"
        content-full-size big
        @cancel="dataState.modalClose()">
      <template v-slot:content v-if="dataState.isSuccess">
        <service-code-view-detail v-if="item!==null" :item="item"/>
        <alert-view v-else icon="mdi-information-outline" text="Не найдено" />
      </template>
    </dialog-view>
  </div>
</template>

<script>
import axios from "axios";
import State from "@/plugins/state";
import DialogView from "@/components/DialogView";
import ServiceCodeViewDetail from "@/views/Service/ServiceCodeViewDetail";
import AlertView from "@/components/AlertView";
import Chip from "@/componentsV2/base/Chip.vue";

export default {
  components: {Chip, AlertView, ServiceCodeViewDetail, DialogView},
  props:['code','contentClass'],
  data:()=>({
    dataState: new State(),
    item:null
  }),
  methods:{
    info(){
      this.dataState.modalOpen();
      this.dataState.stateLoading();
      this.item = null;
      axios.get('/api/service/code/search/', {
        params: {
          q: this.code
        }
      }).then((v) => {
        this.dataState.stateSuccess();
        if(v.data.length>0){
          let b = v.data[0];

          b['additional_info'] = b['additional_info'].split("\n").join("<br/>");
          this.item = {
            text:
                b['id'] + '|' +
                b['code'] + '|' +
                b['name'] + '|' +
                b['additional_info'] + '|' +
                b['dir'],
            value: b
          };
        }
      }).catch((e) => {
        this.dataState.stateError();
        console.log(e);
      })
    }
  }
}
</script>