<template>
  <div v-if="!$tools.isEmpty(phone)" class="d-inline-block">
    <div v-if="typeof big==='undefined'" class="d-inline-block">
      <v-btn
          @click="$store.commit('call', phone)"
          :class="typeof copyDisable==='undefined' ? 'rounded-r-0' : ''" small depressed
      >
        <v-icon class="mr-2" small>mdi-phone-outline</v-icon>
        <span style="text-transform: initial !important">{{typeof title==='undefined' ? $tools.phoneFormat(phone) : title}}</span>
      </v-btn>
      <v-btn
          v-if="typeof copyDisable==='undefined'"
          @click.stop="$tools.copyToClipboard(phone);$store.commit('alertSuccess', 'Скопировано: '+$tools.phoneFormat(phone));"
          class="rounded-l-0 ml-n1" small depressed
      >
        <v-icon size="14" class="mx-n1" color="grey">mdi-content-copy</v-icon>
      </v-btn>
    </div>
    <div v-else class="d-inline-block">
      <v-btn
          @click="$store.commit('call', phone)"
          :class="typeof copyDisable==='undefined' ? 'rounded-r-0' : ''" depressed
      >
        <v-icon small class="mr-2">mdi-phone-outline</v-icon>
        <span style="text-transform: initial !important">{{typeof title==='undefined' ? $tools.phoneFormat(phone) : title}}</span>
        <v-spacer />
      </v-btn>
      <v-btn
          v-if="typeof copyDisable==='undefined'"
          @click.stop="$tools.copyToClipboard(phone);$store.commit('alertSuccess', 'Скопировано: '+$tools.phoneFormat(phone));"
          class="rounded-l-0" depressed
      >
        <v-icon small class="mx-n1" color="grey">mdi-content-copy</v-icon>
      </v-btn>
    </div>
  </div>

</template>

<script>
export default {
  props: ['phone', 'big', 'title', 'copyDisable']
}
</script>

