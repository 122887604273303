<template>
  <v-btn
      text
      icon
      :x-small="typeof big === 'undefined'"
      @click.stop="$tools.copyToClipboard(data);$store.commit('alertSuccess', typeof message==='undefined' ? 'Скопировано' : message);"
    >
    <v-icon :small="typeof big === 'undefined'">mdi-content-copy</v-icon> 
  </v-btn>
</template>

<script>

export default {
  props: [
    'data', 'message', 'big'
  ],
  data: () => ({
    
  }),
}

</script>