<template>
  <dialog-view :open="dataState.isModalOpen" :loading="dataState.isLoading" content-full-size big title="Планы лечения" @cancel="dataState.modalClose()">
    <template v-slot:content>
      <div v-if="dataState.isSuccess">
        <div class="ml-6 my-2">
          <med-plan-from-service-cat-create :userId="userId" @complete="medPlanCreated"/>
        </div>
        <div v-if="medPlans.length===0" class="body-1 pa-4 text-center">Не найдено</div>
        <div v-else>
          <v-text-field
            ref="searchInput"
            v-model="search"
            @keyup="$tools.throttle(()=>searchAction())" @input="$tools.throttle(()=>searchAction())"
            placeholder="Введите текст..."
            background-color="white"
            clearable
            outlined dense hide-details="auto"
            class="px-5">
          <template v-slot:prepend-inner>
            <v-icon>mdi-magnify</v-icon>
          </template>
        </v-text-field>
          <v-expansion-panels accordion v-model="medPlanSelect" multiple class="fill-height">
            <v-expansion-panel v-for="medPlan in medPlans" :key="'medPlan'+medPlan.id">
              <v-expansion-panel-header>
                <v-row class="align-center">
                  <v-col>
                    <div class="font-weight-medium">{{$tools.isEmpty(medPlan.name) ? 'Без названия' : medPlan.name}}</div>
                  </v-col>
                  <v-col cols="auto" class="caption">
                    <div>Стоимость: {{$tools.price(medPlan.summary.total)}}</div>
                    <div>Осталось: {{$tools.price(medPlan.summary.wait)}}</div>
                  </v-col>
                  <v-col cols="auto" class="caption">
                    <div>Оплачено: {{$tools.price(medPlan.summary.success)}}</div>
                    <div>Отменено: {{$tools.price(medPlan.summary.cancel)}}</div>
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <div class="mt-1 mr-2">Назначено: {{medPlan.begin|date}}</div>
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <v-chip v-if="medPlan.status==='active'" class="status" small color="success">Активен</v-chip>
                    <v-chip v-if="medPlan.status==='complete'" class="status" small color="grey" dark>Завершен</v-chip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pt-2">
                  <v-col>
                    <v-btn small dense depressed @click="medPlanId=parseInt(medPlan.id)">Открыть план лечения</v-btn>
                    <div v-if="!$tools.isEmpty(medPlan.comment)" class="mt-4">
                      <v-icon small color="grey" class="position-absolute">mdi-comment-text-outline</v-icon>
                      <div class="ml-6">{{medPlan.comment}}</div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <!-- if only one subCategory -->
                    <v-list class="pa-0" v-if="Object.keys(medPlan.visitServiceSections).filter(key=>key!=='sectionUncategorized').length === 1||
                      (Object.keys(medPlan.visitServiceSections).length === 1&&typeof medPlan.visitServiceSections.sectionUncategorized !=='undefined')">
                      <template v-for="visitService in medPlan.visitServices">
                        <v-list-item                        
                            :key="'vS_'+visitService.id"
                            @click="selectAdd(visitService)"
                            :class="visitService._selected ? 'v-list-item--active' : ''"
                            :disabled="visitService.visit!==null&&!$inactiveStatuses.includes(visitService.visit?.status)"
                            v-show="typeof visitService.hidden === 'undefined'&&visitService.payStatus!=='success'&&visitService.visit?.status!=='complete'"
                        >
                          <v-list-item-content>
                            <v-row class="align-center">
                              <v-col cols="4" class="body-2 py-3">
                                {{visitService.name}}
                                <div v-if="visitService.comment!==null" class="mt-1">
                                  <v-icon small>mdi-comment-text-outline</v-icon>
                                  <div class="caption mt-n5 ml-5">{{visitService.comment}}</div>
                                </div>
                              </v-col>
                              <v-col cols="2" class="text-center">
                                <span v-if="(visitService.visit===null||$inactiveStatuses.includes(visitService.visit?.status))&&visitService.payStatus==='wait'" v-html="formatDate(visitService.startDayDate, visitService.endDayDate, visitService.visit)"></span>
                                <v-chip small v-else>                                  
                                  <v-icon small left>
                                    mdi-check
                                  </v-icon>
                                  запись на
                                  {{visitService.visit?.begin|date}}
                                </v-chip>                              
                              </v-col>
                              <v-col cols="2" class="text-center">
                                <v-chip small outlined v-if="visitService.code">
                                  {{visitService.code }}
                                </v-chip>
                              </v-col>
                              <v-col cols="2" class="text-center">
                                <v-chip v-if="visitService.service.serviceLabItems.length>0" class="mt-1 mr-2" small outlined>
                                 {{formatLab(visitService.service.serviceLabItems)}}
                                </v-chip>
                              </v-col>  
                              <v-col cols="2" class="body-2 py-3 text-right">{{$tools.price(visitService.priceTotal)}}</v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                 
                    <!-- sections -->
                  
                    <v-list class="pa-0" v-else>
                      <template v-for="section in medPlan.visitServiceSections">              
                      <v-expansion-panel  v-if="section.visitServices.filter(v=>v.visit===null||$inactiveStatuses.includes(v.visit?.status)).length > 0" :key="'section'+section.id">
                        <v-expansion-panel-header>
                          <v-row>
                            <v-col>
                              <div class="mt-1 font-weight-medium">{{$tools.isEmpty(section.name) ? 'Без названия' : section.name}}</div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <template v-for="visitService in section.visitServices">
                                <v-list-item                        
                                    :key="'vS_'+visitService.id"
                                    @click="selectAdd(visitService)"
                                    :class="visitService._selected ? 'v-list-item--active' : ''"
                                    :disabled="visitService.visit!==null&&!$inactiveStatuses.includes(visitService.visit?.status)"
                                    v-show="typeof visitService.hidden === 'undefined'&&visitService.payStatus!=='success'&&visitService.visit?.status!=='complete'"
                                >
                                  <v-list-item-content>
                                    <v-row class="align-center">
                                      <v-col cols="4" class="body-2 py-3">
                                        {{visitService.name}}
                                        <div v-if="visitService.comment!==null" class="mt-1">
                                          <v-icon small>mdi-comment-text-outline</v-icon>
                                          <div class="caption mt-n5 ml-5">{{visitService.comment}}</div>
                                        </div>
                                      </v-col>
                                      <v-col cols="2" class="text-center">
                                        <span v-if="(visitService.visit===null||$inactiveStatuses.includes(visitService.visit?.status))&&visitService.payStatus==='wait'" v-html="formatDate(visitService.startDayDate, visitService.endDayDate, visitService.visit)"></span>
                                        <v-chip small v-else> 
                                          <v-icon small left>
                                            mdi-check
                                          </v-icon>
                                          запись на {{visitService.visit?.begin|date}}
                                        </v-chip> 
                                      </v-col>
                                      <v-col cols="2" class="text-center">
                                        <v-chip small outlined v-if="visitService.code">
                                          {{visitService.code }}
                                        </v-chip>
                                      </v-col>
                                      <v-col cols="2" class="text-center">
                                        <v-chip v-if="visitService.service.serviceLabItems.length>0" class="mt-1 mr-2" small outlined>
                                         {{formatLab(visitService.service.serviceLabItems)}}
                                        </v-chip>
                                      </v-col>  
                                      <v-col cols="2" class="body-2 py-3 text-right">{{$tools.price(visitService.priceTotal)}}</v-col>
                                    </v-row>
                                  </v-list-item-content>
                                </v-list-item>
                                </template>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      </template>   
                    </v-list>
                  <!-- sections -->
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <med-plan :medPlanId.sync="medPlanId" @cancel="load()"/>
    </template>
    <template v-if="dataState.isSuccess" v-slot:actions>
      <v-btn :disabled="selected.length===0" text color="primary" @click="selectComplete()">
        Добавить ({{selected.length}})
      </v-btn>
    </template>
  </dialog-view>
</template>

<script>
import State from "@/plugins/state";
import DialogView from "@/components/DialogView";
//import Api from "@/Api";
import MedPlan from "@/views/MedPlan/MedPlan";
import MedPlanFromServiceCatCreate from "@/views/MedPlan/MedPlanFromServiceCatCreate";
import moment from "moment";  
import {
  Entity_MedPlan, 
  Entity_User, 
  Entity_UserProfile, 
  Entity_VisitService, 
  Entity_ServiceCat, 
  Entity_Service, 
  Entity_Visit,
  Entity_ServiceLabItem
} from "../../../EntityStoreCacheService";

export default {
  components: {MedPlanFromServiceCatCreate, MedPlan, DialogView},
  props: ['open', 'userId', 'hideFree'],
  data: () => ({
    dataState: new State(),
    medPlanStatusState: new State(),
    medPlans:[],
    selected:[],
    medPlanSelect:[],
    medPlanId:null,
    search: null,
  }),
  computed: {
    formatDate() {
      return (start, end, visit) => {
        if (start===null&end===null) return '<i aria-hidden="true" class="v-icon notranslate mdi mdi-calendar-alert-outline theme--light" style="cursor:pointer;"></i>'
        
        const currentDate = moment();
        const startDay = this.$tools.date(start,'DD.MM.YYYY');
        const endDay = end!==null ? this.$tools.date(end,'DD.MM.YYYY'):'';
        const divider = start&&end ? ' - ' : '';
        const prefix = start&&!end ? 'c ' : !start&&end ? 'по ' : '';
        let alert = false;

        if (visit===null||visit?.status!=='complete') {
          if (end!==null) {
            if(moment(end).subtract(1, 'days').diff(moment(currentDate), 'days')<0) alert=true
          } 
          // else {
          //   if(moment(start).diff(moment(currentDate), 'days')<0) alert=true
          // }
        }

        if(alert===true) {
          return '<span class="v-chip v-size--small red white--text" style="cursor:pointer;">'+prefix+startDay+divider+endDay+'</span>';
        } else {
          return '<span class="v-chip theme--light v-size--small" style="cursor:pointer;">'+prefix+startDay+divider+endDay+'</span>';
        }
      };
    },
    formatLab(){
      return (serviceLabItem) =>{
        if (serviceLabItem.length>0) {
          let arr = [];
          serviceLabItem.forEach(l=>{
            arr.push(l.labId);
          })
          return arr.join(', ');
        } else {
          return '-';
        }
      }
    }
  },
  watch: {
    open: function() {
      if (this.open){
        this.dataState.modalOpen();
        this.focusSearchInput();
        this.medPlanSelect = [];
        this.selected = [];
        this.load();
      } else {
        this.dataState.modalClose();
      }
    },
    dataState: {
      handler () {
        this.$emit('update:open', this.dataState.isModalOpen);
      },
      deep: true,
    },
  },
  methods: {
    focusSearchInput(){
      setTimeout(()=>{
        let els = this.$refs.searchInput;
        if (els!=null){
          if (typeof els[0]==='undefined') els.focus();
          else els[0].focus();
        }
      }, 100);
    },

    load() {
      this.dataState.stateLoading();

      let MedPlanRequest = new Entity_MedPlan()
      .selects(Entity_MedPlan.id)
      .selects(Entity_MedPlan.name)
      .selects(Entity_MedPlan.begin)
      .selects(Entity_MedPlan.end)
      .selects(Entity_MedPlan.plannedDate)
      .selects(Entity_MedPlan.comment)
      .selects(Entity_MedPlan.status)
      //user
      .selects([Entity_MedPlan.user, Entity_User.id].join('.'))
      .selects([Entity_MedPlan.user, Entity_User.firstName].join('.'))
      .selects([Entity_MedPlan.user, Entity_User.lastName].join('.'))
      .selects([Entity_MedPlan.user, Entity_User.secondName].join('.'))
      //userSpec
      .selects([Entity_MedPlan.userSpec, Entity_User.id].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.type].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.firstName].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.lastName].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.secondName].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))

      //virtual
      // .virtual(Entity_MedPlan.virtual_isOverDate)
      .virtual(Entity_MedPlan.virtual_summary)
    
      .filterAnd([Entity_MedPlan.entity, Entity_MedPlan.status].join('.')+' in (?)',['active','achieved'])//активный и цель достигнута
      .filterAnd([Entity_MedPlan.entity, Entity_MedPlan.user, Entity_User.id].join('.')+'=?',this.userId)
      .page(1).onPage(1000);
      
      MedPlanRequest.request((data)=>{
        let medPlans = data.items;
        const promises = [];

        medPlans.forEach(mp=>{

          const getVisitServices = new Promise((resolve) => {
          //visitService
            let VisitServiceRequest = new Entity_VisitService()
              .selects(Entity_VisitService.id)
              .selects(Entity_VisitService.type)
              .selects(Entity_VisitService.code)
              .selects(Entity_VisitService.comment)
              .selects(Entity_VisitService.count)
              .selects(Entity_VisitService.discount)        
              .selects(Entity_VisitService.duration)                     
              .selects(Entity_VisitService.isid)           
              .selects(Entity_VisitService.name)           
              .selects(Entity_VisitService.payStatus)          
              .selects(Entity_VisitService.price)         
              .selects('priceTotal')     
              .selects(Entity_VisitService.isShowForClient)   
              .selects(Entity_VisitService.startDay)  
              .selects(Entity_VisitService.endDay)  

              .virtual(Entity_VisitService.virtual_startDayDate)
              .virtual( Entity_VisitService.virtual_endDayDate)

              //Visit
              .selects([Entity_VisitService.visit, Entity_Visit.id].join('.'))
              .selects([Entity_VisitService.visit, Entity_Visit.begin].join('.'))
              .selects([Entity_VisitService.visit, Entity_Visit.status].join('.'))
              

              //service
              .selects([Entity_VisitService.service, Entity_Service.id].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceLabItems, Entity_ServiceLabItem.labId].join('.'))

              
              // .selects([Entity_VisitService.service, Entity_Service.startDay].join('.'))
              // .selects([Entity_VisitService.service, Entity_Service.endDay].join('.'))
              .selects([Entity_VisitService.serviceCat, Entity_ServiceCat.id].join('.'))
              .selects([Entity_VisitService.serviceCat, Entity_ServiceCat.name].join('.'))
              .selects([Entity_VisitService.serviceCat, Entity_ServiceCat.isProgram].join('.'))

              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.name].join('.'))
              .selects([Entity_VisitService.service, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_Service.serviceCat, Entity_ServiceCat.isProgram].join('.'))

              .filterAnd([Entity_VisitService.entity, Entity_VisitService.medPlan, Entity_MedPlan.id].join('.')+'=?',mp.id)


              .page(1).onPage(3000);

              VisitServiceRequest.request((vc)=>{
              
                mp.visitServices = vc.items;
            
                //sort by startDayDate
                if(mp.visitServices.filter(item=> item.startDayDate!==null).length>0){
                  const medPlanFiltered = mp.visitServices.sort((a, b) => new Date(a.startDayDate) - new Date(b.startDayDate));
                  mp.visitServices = medPlanFiltered;
                }
        

                mp.visitServiceSections = {};

                let putIntoSection = (service,serviceCatId,serviceCatName) => {
                    if (typeof mp.visitServiceSections['section'+serviceCatId] ==='undefined') {
                      mp.visitServiceSections['section'+serviceCatId] = {name:null,visitServices:[]};
                      mp.visitServiceSections['section'+serviceCatId].name = serviceCatName;
                      mp.visitServiceSections['section'+serviceCatId].id = serviceCatId;
                    }
                    
                    mp.visitServiceSections['section'+serviceCatId].visitServices.push(service);
                }
    
                mp.visitServices.forEach(vs => {
                  
                  function checkIsProgram(obj,service) {
            
                      if (obj.isProgram) {
                        putIntoSection(service,obj.id,obj.name);
                      } else {
                        if (obj.serviceCat) {
                          checkIsProgram(obj.serviceCat,vs);
                        } else {
                          putIntoSection(service,'Uncategorized','Без категории');
                        }
                      }
                    
                  }
    
                  if (vs.serviceCat){
                    putIntoSection(vs,vs.serviceCat.id,vs.serviceCat.name);
                  } else {
                    checkIsProgram(vs.service.serviceCat,vs);
                  }
    
                });

                  let sortedObj = Object.fromEntries(
                    Object.entries(mp.visitServiceSections).sort(([, a], [, b]) => a.id - b.id)
                  );

                  mp.visitServiceSections = sortedObj
   
                resolve();
                
              }, (e)=>{
                console.error(e.response.data.message);
              })
              
          });

          promises.push(getVisitServices);
        });
    
        //visitServices loaded
        Promise.all(promises).then(() => {
          this.medPlans = medPlans;
          this.dataState.stateSuccess();
        });  

      }, (e)=>{
        console.error(e.response.data.message);
        this.dataState.stateError(e)
      })
    },

    selectAdd(visitService){
      // this.focusSearchInput();
      if (this.selected.findIndex(v => v.id===visitService.id)===-1){
        this.selected.push(visitService);
        visitService._selected = true;
      } else {
        this.selected = this.selected.filter(v => v.id!==visitService.id);
        visitService._selected = false;
      }
    },

    selectComplete(){
      this.dataState.modalClose();
      let arr = [];
      this.selected.forEach((v)=>{
        arr.push(v.id);
      });
      this.$emit('complete', arr);
    },

    medPlanCreated(id){
      this.medPlanId = id;
    },
    searchAction() {
      this.dataState.stateLoading();

      let data = this.medPlans;
      let regExp = new RegExp(this.search,'gi');

      data.forEach((program,programIndex)=>{
        program.visitServices.forEach((i,index)=>{
          if (i.hidden===true){
            delete data[programIndex].visitServices[index].hidden;
          }
          if (this.search!==null&&this.search.length>0&&!i.name.match(regExp)) {
            data[programIndex].visitServices[index].hidden = true;
          }
        })
      })

      this.medPlans = data

      this.dataState.stateSuccess();
    } 
  }
}
</script>


<style lang="scss">
.statusContainer {
  display: inline-block;
  text-align: center;
  .status{
    width: 100px !important;
    .v-chip__content{
      margin: auto !important;
    }
  }
}
</style>