<template>
  <v-sheet>
    <autocomplete-multiple v-if="typeof single==='undefined'" class="mb-0" name="Организации" :items="items" :select.sync="selected" @load="load()" :loading="state.isLoading">
      <template v-slot:itemInfo="{ item }">
        <div class="caption">
          <span v-if="item.oms">(ОМС)</span>
          <span v-if="item.dms">(ДМС)</span>
          <span v-if="item.contract">(Контракт)</span>
        </div>
      </template>
    </autocomplete-multiple>
    <autocomplete-single v-else name="Организация" class="mb-0" :items="items" :select.sync="selected" @load="load()" :loading="state.isLoading">
      <template v-slot:itemInfo="{ item }">
        <div class="caption">
          <span v-if="item.oms">(ОМС)</span>
          <span v-if="item.dms">(ДМС)</span>
          <span v-if="item.contract">(Контракт)</span>
        </div>
      </template>
    </autocomplete-single>
  </v-sheet>
</template>

<script>

import State from "@/plugins/state";
import Api from "@/Api";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";

export default {
  components: {AutocompleteMultiple, AutocompleteSingle},
  props:['type', 'single', 'onlyContract'],
  data:()=>({
    selected:null,
    items:[],
    text:null,
    state: new State(),
  }),
  watch:{
    selected(){
      if(Array.isArray(this.selected)){
        this.$emit('selected', this.selected);
      } else {
        this.$emit('selected', this.selected===null ? null : this.selected.id);
      }
    }
  },
  methods:{
    load(){
      if(this.items.length===0) {
        let query = `
          query Query(
            $name_like: String
          ){
            Insur(
              sortBy:"name"
              name_like:$name_like
            ){
              id
              name
              oms
              dms
              contract
            }
          }
        `;
        let queryVars = {
          name:this.text
        };

        this.state.stateLoading();
        Api.graphql(query, queryVars, (data)=>{
          this.state.stateSuccess()
          this.items = data.Insur;
          if(typeof this.onlyContract!=='undefined')
            this.items = this.items.filter(v=>v.contract);
        }, (e)=>{
          this.state.stateError(e)
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .insure-item {
    position: relative;
    width: 100%;
    &__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
    }
    &__label {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
</style>