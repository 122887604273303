<template>
  <dialog-view
      :open="userId!==null" :loading="loadState.isLoading"
      title="Оплатить визиты"
      big contentFullSize @cancel="close()"
  >

    <template v-slot:barActions>
      <v-btn @click="select(0)" small depressed color="grey lighten-3" class="mr-2"><v-icon small class="mr-1">mdi-checkbox-multiple-outline</v-icon>Прошедшие</v-btn>
      <v-btn @click="select(1)" small depressed color="grey lighten-3" class="mr-2"><v-icon small class="mr-1">mdi-checkbox-multiple-outline</v-icon>С начала дня</v-btn>
      <v-btn @click="select(2)" small depressed color="grey lighten-3" class="mr-2"><v-icon small class="mr-1">mdi-checkbox-multiple-outline</v-icon>За весь день</v-btn>
      <v-btn @click="select(3)" small depressed color="grey lighten-3" class="mr-2"><v-icon small class="mr-1">mdi-checkbox-multiple-outline</v-icon>Будущие</v-btn>
      <v-btn @click="select(4)" small depressed color="grey lighten-3" class="mr-2"><v-icon small class="mr-1">mdi-checkbox-multiple-outline</v-icon>Все</v-btn>
    </template>
    <template v-slot:content v-if="loadState.isSuccess || visits.length>0">
      <v-data-table
        :headers="[
              { text: 'ID', value: 'id', class:'text-no-wrap', sortable:false},
              { text: 'Статус', value: 'status', class:'text-no-wrap', sortable:false},
              { text: 'Специалист', value: 'userSpec', class:'text-no-wrap', sortable:false},
              { text: 'Осталось',  class:'text-no-wrap', sortable:false},
              { text: 'Дата визита', value: 'begin', class:'text-no-wrap', width: '100%', sortable:false},
            ]"
        :items="visits"
        :server-items-length="visits.length"
        hide-default-footer
        :items-per-page="100"
        dense
        show-select
        class="tableMultipleSelectStatused"
      >
        <template v-slot:header.data-table-select="">
          <v-checkbox v-model="selectedAll" class="ma-0 pa-0" dense hide-details />
        </template>
        <template v-slot:item="{item}">
          <tr v-if="statuses.length>0" @click="visitBeginId=item.id">
            <td>
              <v-checkbox @click.stop="selectedAddRemove(item)" :input-value="isSelected(item)" class="ma-0 pa-0" dense hide-details />
            </td>
            <td class="text-no-wrap">{{item.id}}</td>
            <td class="text-no-wrap">
              <visit-status :status="$tools.findById(statuses, item.status)" :isPaidFull="item.paySummary.isPaidFull" :isSecond="item.isSecond" small/>
            </td>
            <td class="text-no-wrap position-relative">
              <div class="position-absolute mt-2">
                <v-avatar class="user" size="32">
                  <img :src="item.userSpec.user.imageUrl" v-if="item.userSpec.user.imageUrl">
                </v-avatar>
              </div>
              <div class="ml-10 mt-1">
                {{item.userSpec.user.lastName != null ? item.userSpec.user.lastName + ' ' : ''}}
                {{item.userSpec.user.firstName != null ? item.userSpec.user.firstName + ' ' : ''}}
                {{item.userSpec.user.secondName != null ? item.userSpec.user.secondName + ' ' : ''}}
              </div>
              <div class="ml-10 caption mt-n1 mb-1">
                {{item.userSpec.spec.name}}
              </div>
            </td>
            <td class="text-no-wrap text-right">{{item.paySummary.wait.toFixed(2)}} Р</td>
            <td class="text-no-wrap">{{$tools.date(item.begin)}} {{$tools.time(item.begin)}} - {{$tools.time(item.end)}}</td>
          </tr>
        </template>
      </v-data-table>
      <v-divider/>

      <visit1
          :visitId.sync="visitBeginId"
          :users-specs="usersSpecs"
          :specs="specs"
      />
    </template>
    <template v-slot:actions>
      <v-btn text color="primary" :disabled="visitsSelected.length===0" :loading="payCreateState.isLoading" @click="payCreateAction()">
        <v-icon small class="pr-2">mdi-cart</v-icon>Оплатить ({{$tools.price(visitsSelectedSum)}})
      </v-btn>
      <pay-create
          :open="payCreateState.isModalOpen"
          :visitServices="visitServices"
          :userId="userId"
          @complete="payCreateState.modalClose();load()"
      />
    </template>

  </dialog-view>

</template>

<script>
import State from "@/plugins/state";
import DialogView from "@/components/DialogView";
import axios from "axios";
import VisitStatus from "@/components/VisitStatus";
import Api from "@/Api";
import moment from "moment";
import PayCreate from "@/views/Pay/PayCreate.vue";

export default {
  components: {PayCreate, Visit1: () => import('@/views/Visit/Visit'), VisitStatus, DialogView},
  props: ['userId', 'statuses', 'sources', 'usersSpecs', 'specs'],
  data: () => ({
    loadState: new State(),
    visits:[],
    visitsSelected:[],
    visitsSelectedSum:0,
    visitServices:[],

    visitBeginId:null,
    payCreateState: new State(),
  }),
  watch:{
    userId(){
      if(this.userId!==null){
        this.visits = [];
        this.visitsSelected = [];
        this.load();
      }
    },
    visitBeginId(){
      if(this.visitBeginId===null)
        this.load();
    }
  },
  computed:{
    selectedAll:{
      get() {
        return this.visitsSelected.length===this.visits.length;
      },
      set(v) {
        this.visitsSelected = v ? this.visits : [];
        this.visitsSelectedSumUpdate();
      }
    },
    visitsIds(){
      let visitsIds = [];
      this.visitsSelected.forEach((v)=>{
        visitsIds.push(v.id);
      });
      return visitsIds;
    }
  },
  methods: {
    load(){
      this.loadState.stateLoading();
      this.visitsSelectedSumUpdate();
      axios.get(Api.host+'/visit/paySummaryWait/byUser/'+this.userId+'/', {withCredentials: true})
        .then(v =>{
          this.visits = [];
          this.visitsSelected = [];
          this.selectedAll = false;
          v.data.forEach((v)=>{
            this.visits.push(v);
          });
          this.select(1);
          this.loadState.stateSuccess();
        })
        .catch(() =>{
          this.loadState.stateError();
        });
    },

    selectedAddRemove(item){
      if(!this.isSelected(item)){
        this.visitsSelected.push(item);
      } else {
        this.visitsSelected = this.visitsSelected.filter((v)=>{
          if(v.id===item.id)
            return false;
          return true;
        });
      }
      this.visitsSelectedSumUpdate();
    },

    isSelected(item){
      let find = false;
      this.visitsSelected.forEach((v)=>{
        if(v.id===item.id)
          find = true;
      });
      return find;
    },

    select(mode){
      let todayBegin = moment().startOf('day').unix();
      let todayEnd = moment().endOf('day').unix();
      let now = moment().unix();
      this.visitsSelected = [];
      this.selectedAll = false;

      if(mode===0){
        this.visits
            .filter(v=>moment(v.begin, 'YYYY-MM-DD HH:mm:ss').unix()<todayBegin)
            .forEach((v)=>{
              this.selectedAddRemove(v)
            });
      } else if(mode===1){
        this.visits
            .filter(v=>moment(v.begin, 'YYYY-MM-DD HH:mm:ss').unix()>=todayBegin && moment(v.begin, 'YYYY-MM-DD HH:mm:ss').unix()<=now)
            .forEach((v)=>{
              this.selectedAddRemove(v)
            });
      } else if(mode===2){
        this.visits
            .filter(v=>moment(v.begin, 'YYYY-MM-DD HH:mm:ss').unix()>=todayBegin && moment(v.begin, 'YYYY-MM-DD HH:mm:ss').unix()<=todayEnd)
            .forEach((v)=>{
              this.selectedAddRemove(v)
            });
      } else if(mode===3){
        this.visits
            .filter(v=>moment(v.begin, 'YYYY-MM-DD HH:mm:ss').unix()>now)
            .forEach((v)=>{
              this.selectedAddRemove(v)
            });
      } else {
        this.visits
            .forEach((v)=>{
              this.selectedAddRemove(v)
            });
      }
    },

    visitsSelectedSumUpdate(){
      this.visitsSelectedSum = 0;
      this.visitsSelected.forEach((v)=>{
        this.visitsSelectedSum+=v.paySummary.wait;
      });
    },

    payCreateAction(){
      this.payCreateState.stateLoading();
      let url = Api.host+'/visit/visitService/?';
      this.visitsSelected.forEach((v)=>{
        url+='visitId[]='+v.id+'&';
      });
      axios.get(url)
          .then(r =>{
            this.visitServices = [];
            r.data.data.filter(v=>v.payStatus==='wait').forEach((v)=>{
              this.visitServices.push(v);
            });
            this.payCreateState.stateSuccess();
            this.payCreateState.modalOpen();
          })
          .catch(() =>{
            this.payCreateState.stateError();
          });
    },

    close(){
      this.$emit('close');
    },
  }
}
</script>
