<template>
  <div v-if="typeof compact!=='undefined'">
    <div v-for="k in Object.keys(sources)" :key="k" class="d-inline-block">
      <tooltip-view v-if="parseFloat(pay['amount'+k.charAt(0).toUpperCase()+k.slice(1)])>0">
        <template v-slot:action>
          <v-icon
              :color="
                k==='cash' ? '' :
                k==='card' ? '' :
                k==='account' ? '' :
                k==='bankAccount' ? (pay.isAcceptBankAccount ? '' : 'warning') :
                k==='oms' ? (pay.isAcceptOms ? '' : 'warning') :
                k==='dms' ? (pay.isAcceptDms ? '' : 'warning') :
                k==='contract' ? (pay.isAcceptContract ? '' : 'warning') : ''
              "
              size="18" class="d-inline-block mr-2" style="margin-top:-2px"
          >
            {{sources[k].icon}}
          </v-icon>
        </template>
        <template v-slot:content>
          <div class="body-2 pb-2">{{sources[k].name}}</div>
        </template>
      </tooltip-view>
    </div>
  </div>
  <div v-else>
    <div v-for="k in Object.keys(sources)" :key="k">
      <div v-if="parseFloat(pay['amount'+k.charAt(0).toUpperCase()+k.slice(1)])>0">
        <v-row no-gutters align="center">
          <v-col cols="auto">
            <v-sheet color="transparent" height="28" class="align-center d-flex">
              <v-icon size="18" class="mr-2" style="margin-top:-2px">{{sources[k].icon}}</v-icon>
            </v-sheet>
          </v-col>
          <v-col class="align-center d-flex" cols="auto">
            {{sources[k].name}} : {{$tools.price(pay['amount'+k.charAt(0).toUpperCase()+k.slice(1)])}}
          </v-col>
          <v-col v-if="k==='oms' || k==='dms' || k==='insur'" cols="auto" class="pl-2">
            {{$tools.isEmpty(pay.insur) ? '' : pay.insur.name}}
          </v-col>
          <v-col v-if="k==='bankAccount' || k==='oms' || k==='dms' || k==='contract'" cols="auto">
            <btn-question v-if="k==='bankAccount'" @click="accept(k)" :title="pay.isAcceptBankAccount ? 'Отменить подтверждение?' : 'Подтвердить?'" icon class="ml-1">
              <v-icon size="18" :color="pay.isAcceptBankAccount ? 'success' : 'warning'">
                {{pay.isAcceptBankAccount ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}
              </v-icon>
            </btn-question>
            <btn-question v-if="k==='oms'" @click="accept(k)" :title="pay.isAcceptOms ? 'Отменить подтверждение?' : 'Подтвердить?'" icon class="ml-1">
              <v-icon size="18" :color="pay.isAcceptOms ? 'success' : 'warning'">
                {{pay.isAcceptOms ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}
              </v-icon>
            </btn-question>
            <btn-question v-if="k==='dms'" @click="accept(k)" :title="pay.isAcceptDms ? 'Отменить подтверждение?' : 'Подтвердить?'" icon class="ml-1">
              <v-icon size="18" :color="pay.isAcceptDms ? 'success' : 'warning'">
                {{pay.isAcceptDms ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}
              </v-icon>
            </btn-question>
            <btn-question v-if="k==='contract'" @click="accept(k)" :title="pay.isAcceptContract ? 'Отменить подтверждение?' : 'Подтвердить?'" icon class="ml-1">
              <v-icon size="18" :color="pay.isAcceptContract ? 'success' : 'warning'">
                {{pay.isAcceptContract ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}
              </v-icon>
            </btn-question>
          </v-col>
          <v-col v-if="k==='card' && !$tools.isEmpty(pay.slipCard)" cols="auto" class="ml-1">
            <tooltip-view>
              <template v-slot:action>
                <v-icon small class="pa-1">mdi-receipt-text-outline</v-icon>
              </template>
              <template v-slot:content>
                <div class="caption" v-html="$tools.nl2br(pay.slipCard)"></div>
              </template>
            </tooltip-view>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import TooltipView from "@/components/TooltipView.vue";
import BtnQuestion from "@/components/btnQuestion.vue";
import axios from "axios";

export default {
  components: {BtnQuestion, TooltipView},
  props:['pay','compact'],
  data: () => ({
    sources:Api.model.Pay.sources,
  }),
  methods:{
    accept(type){
      axios.put(Api.host+'/pay/pay/'+this.pay.id+'/accept/'+type+'/')
          .then(()=>{
            if(type==='bankAccount') this.pay.isAcceptBankAccount = !this.pay.isAcceptBankAccount;
            if(type==='oms') this.pay.isAcceptOms = !this.pay.isAcceptOms;
            if(type==='dms') this.pay.isAcceptDms = !this.pay.isAcceptDms;
            if(type==='contract')this.pay.isAcceptContract = !this.pay.isAcceptContract;
          });
    }
  }
}
</script>