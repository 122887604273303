<template>

  <v-text-field
      v-model="localDate"
      :value="$tools.date(date)"
      :label="title"
      :rules="typeof this.required!=='undefined' ? [rules.required, rules.counter] : []"
      outlined 
      dense 
      hide-details="auto"
      v-mask="'##.##.####'"
      />

  </template>

<script>
import moment from "moment";


export default {
  props: [
    'title',
    'date',
    'required',
  ],
  data:()=>({
    rules: {
      required: value => !!value || 'Поле обязательно',
      counter: value => value ? value.length == 10&&moment(value,'DD.MM.YYYY').isValid() : ''
    },
    valid: false
  }),
  computed:{
    localDate:{
      get(){
        if (this.date !== null) {
          return moment(this.date).format('DD.MM.YYYY');
        }
        else {
          return null
        }
      },
      set(v){
        if (v!==null) {
          if(v.length == 10&&moment(v,'DD.MM.YYYY').isValid())
            this.$emit('update:date', moment(v,'DD.MM.YYYY').format());
          else 
          this.$emit('update:date', null);
        }
            
      }
    }
  }
}
</script>