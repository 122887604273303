<template>
  <div class="visitServiceStatuses">
    <v-chip v-if="status==='wait'" class="status" small color="warning">Не оплачено</v-chip>
    <v-chip v-if="status==='success'" class="status" small color="success">Оплачено</v-chip>
    <v-chip v-if="status==='cancel'" class="status" small color="error">Отменено</v-chip>
  </div>
</template>

<script>
export default {
  props: ['status'],
}
</script>

<style lang="scss">
.visitServiceStatuses{
  display: inline-block;
  .status{
    width: 120px !important;
    .v-chip__content{
      margin: auto !important;
    }
  }
}
</style>