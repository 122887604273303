<template>
  <v-menu v-model="open" :close-on-content-click="false" max-width="250">
    <template v-slot:activator="{ on, attrs }">
      <v-btn block depressed v-bind="attrs" v-on="on" :loading="state.isLoading" :disabled="disabled===true || extensionNotFound">
        <v-icon small class="pr-2">mdi-open-in-new</v-icon>Заявка в лаб.
      </v-btn>
    </template>
    <v-list class="pa-0">
      <v-list-item link @click="request('alfalab', 'https://lab.mobil-med.org/cabinet/partner/journal/request/create')">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">МОБИЛ МЕДИКАЛ ЛАБ</v-list-item-title>
          <div class="caption">lab.mobil-med.org</div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <progress-page v-if="labsState.isLoading"/>
    <v-list class="pa-0">
      <v-list-item v-for="(v,k) in labs.filter(v=>v.id!=='medicallab')" :key="k" link @click="request(v.id, v.panelUrl)">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{v.name}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axios from "axios";
import Api from "@/Api";
import State from "@/plugins/state";
import ProgressPage from "@/components/ProgressPage.vue";

export default {
  components: {ProgressPage},
  props:[
    'userId',
    'visitServices',
    'comBranchId',
    'disabled',
  ],
  data:()=>({
    extensionNotFound:true,
    state: new State(),
    open:false,
    labsState:new State(),
    labs:[],
  }),
  mounted() {
    window.chrome.runtime.sendMessage('phljeejcllckoaeajfcnpdnmjkgfghfn', {command:'status'}, (r)=>{
      if(r.status==='ok')
        this.extensionNotFound = false
    });
  },
  watch:{
    open(){
      if(this.open){
        this.labsState.stateLoading();
        axios.get('/napi/lab/')
            .then(r =>{
              this.labs = r.data;
              this.labsState.stateSuccess();
            })
            .catch(() =>{
              this.labsState.stateError();
            });
      }
    }
  },
  methods:{
    request(name, url){
      axios.get(Api.host+'/user/'+this.userId+'/')
        .then((r)=>{
          let d = {
            url:url,
            firstName: this.$tools.isEmpty(r.data.firstName) ? null : r.data.firstName,
            lastName: this.$tools.isEmpty(r.data.lastName) ? null : r.data.lastName,
            secondName: this.$tools.isEmpty(r.data.secondName) ? null : r.data.secondName,
            birth: this.$tools.isEmpty(r.data.userProfile.birth) ? null : this.$tools.dateTimeFormat(r.data.userProfile.birth,'DD.MM.YYYY'),
            genderMale: r.data.userProfile.gender==='male',
            email: this.$tools.isEmpty(r.data.userProfile.email) ? null : r.data.userProfile.email,
            sid: r.data.id,
            phone: this.$tools.isEmpty(r.data.phone) ? null : r.data.phone,
            address:(()=>{
              let v = this.$tools.isEmpty(r.data.userProfile.resAddrStreet) ? '' : r.data.userProfile.resAddrStreet;
              if(!this.$tools.isEmpty(v))
                v += ' '+this.$tools.isEmpty(r.data.userProfile.resAddrRoom) ? '' : r.data.userProfile.resAddrRoom

              v = v.trim();
              if(this.$tools.isEmpty(v))
                return null;

              return v;
            })(),
            /*
            items:[
              {code:'20.16.005', options:null},
              {code:'20.27.001', options:'Сухая капля крови,Моча разовая'}
            ]
             */
            items:(()=>{
              console.log(this.visitServices);
              let arr = [];
              this.visitServices.forEach(v=>{
                if(name==='alfalab' && v.optionsJson!==null && typeof v.optionsJson.alfalab!=='undefined'){
                  v.optionsJson.alfalab.forEach(v1=>{
                    arr.push({code:v1.code, options:v1.options});
                  });
                }
                v.service.serviceLabItems.filter(v1=>v1.labId===name).forEach(v1=>{
                  arr.push({code:v1.code});
                });
              });
              return arr;
            })(),
            comBranchId:this.comBranchId
          }
          console.log(d);

          window.chrome.runtime.sendMessage('phljeejcllckoaeajfcnpdnmjkgfghfn', {command:'request', data:d});
        })
        .catch(e => Api.responseError(e));
    }
  }
}
</script>