<template>
  <div :class="typeof block==='undefined' ? 'd-inline-block' : ''">
    <v-btn
        :color="color!=='undefined' ? color : ''"
        :text="text!=='undefined' ? text : ''"
        :icon="typeof icon!=='undefined'"
        @click="isOpen=true"
        :loading="loading" 
        :size="typeof size!=='undefined' ? size : 'small'"
        :small="typeof small!=='undefined'"
        :block="typeof block!=='undefined'"
        depressed
        :light="typeof light !=='undefined'"
    >
      <slot/>
    </v-btn>
    <v-dialog
        v-model="isOpen"
        @input="isOpen=false"
        @click:outside="isOpen=false"
        :width="typeof width =='undefined' ? 425 : width"
        scrollable
    >
      <v-card class="dialogView">
        <v-toolbar class="bar" dense elevation="0" color="transparent" max-height="48px">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer/>
          <slot name="barActions"/>
          <v-btn icon @click="isOpen=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider v-if="!!$slots.content"/>
        <slot name="content"/>
        <v-divider/>
        <v-card-actions class="footer">
          <slot name="footer" :isOpen="(v)=>isOpen=v">
            <v-btn text @click="isOpen=false">Отмена</v-btn>
            <v-spacer/>
            <v-btn text @click="isOpen=false;$emit('click');" color="primary">OK</v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['title','width','light','loading','icon','color', 'text', 'size','small','block'],
  data: () => ({
    isOpen:false
  })
}
</script>
