<template>
  <div>
    <v-menu class="mr-4" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" depressed small>Создать план лечения</v-btn>
      </template>
      <v-list>
        <v-list-item link @click="selectComplete()">
          <v-list-item-title>Пустой</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="open=true">
          <v-list-item-title>Из программы (категория услуг)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <dialog-view
        :open="open"
        :loading="dataState.isLoading"
        title="Создать план лечения из программы (категория услуг)"
        content-full-size big
        @cancel="open=false">
      <template v-slot:header>
        <v-row no-gutters>
          <v-col>
            <v-text-field
                ref="searchInput"
                v-model="search" label="Поиск..."
                @keyup="searchTimerIndex=Date.now()" @input="searchTimerIndex=Date.now()"
                background-color="white"
                outlined dense hide-details="auto"/>
          </v-col>
        </v-row>
      </template>
      <template v-slot:content>
        <div v-if="dataState.isSuccess">
          <div v-if="searchResults.length===0" class="body-1 pa-4 text-center">Не найдено</div>
          <v-expansion-panels v-else accordion v-model="catSelects" multiple class="fill-height">
            <v-expansion-panel
                v-for="(searchResult, index) in searchResults"
                :key="'searchResult'+index"
            >
              <v-expansion-panel-header>
                <v-breadcrumbs class="pa-0">
                  <div v-for="(bread, i) in searchResult.breads" :key="i">
                    <v-breadcrumbs-item class="body-2 font-weight-medium">
                      <v-icon v-if="bread.isProgram===true" color="green" class="position-absolute mt-n1 ml-n1">mdi-star</v-icon>
                      <span :class="bread.isProgram===true ? 'ml-4': ''">{{bread.text}}</span>
                    </v-breadcrumbs-item>
                    <v-breadcrumbs-divider v-if="i<searchResult.breads.length-1">/</v-breadcrumbs-divider>
                  </div>
                </v-breadcrumbs>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list class="pa-0">
                  <v-list-item
                      v-for="serviceCat in searchResult.serviceCats"
                      :key="'serviceCat'+serviceCat.id"
                      @click="selectAdd(serviceCat)"
                      :class="serviceCat._selected ? 'v-list-item--active' : ''"
                  >
                    <v-list-item-content>
                      <v-row>
                        <v-col class="body-2 py-3">
                          <v-icon v-if="serviceCat.isProgram===true" color="green" class="position-absolute ml-n1" small>mdi-star</v-icon>
                          <div class="ml-4">{{serviceCat.name}}</div>
                          <div v-if="!$tools.isEmpty(serviceCat.comment)" class="mt-1">
                            <v-icon small>mdi-comment-text-outline</v-icon>
                            <div class="caption mt-n5 ml-5">{{serviceCat.comment}}</div>
                          </div>
                        </v-col>
                        <v-col cols="2" class="body-2 py-3 text-right">{{serviceCat.price.toFixed(2)}} Р</v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
      <template v-if="dataState.isSuccess" v-slot:actions>
        <v-btn :disabled="selected.length===0" text color="primary" @click="selectComplete()">
          Добавить ({{selected.length}})
        </v-btn>
      </template>
    </dialog-view>
  </div>
</template>

<script>
import State from "@/plugins/state";
import DialogView from "@/components/DialogView";
import Api from "@/Api";

export default {
  components: {DialogView},
  props:['userId'],
  data: () => ({
    open:false,
    dataState: new State(),
    search: '',
    searchTimer:null,
    searchTimerIndex:0,
    searchIsProgram: false,
    searchResults:[],
    catSelects:[],

    selected:[],
  }),
  watch: {
    open() {
      if (this.open){
        this.searchAction();
        this.focusSearchInput();
        this.search = '';
        this.searchResults = [];
        this.selected = [];
        this.searchTimer = setInterval(()=>{
          if (this.searchTimerIndex!==0){
            if (Date.now()-this.searchTimerIndex>1000){
              this.searchTimerIndex = 0;
              this.searchAction();
            } else {
              this.dataState.stateLoading();
            }
          }
        }, 500, 500);
      } else {
        clearInterval(this.searchTimer);
      }
    },
  },
  mounted(){

  },
  methods: {
    focusSearchInput(){
      setTimeout(()=>{
        let els = this.$refs.searchInput;
        if (els!=null){
          if (typeof els[0]==='undefined') els.focus();
          else els[0].focus();
        }
      }, 100);
    },

    searchAction(){
      this.dataState.stateLoading();
      Api.service.service.search(
        this.search,
        false,
        null,
        false,
        false,
        true,
        true,
        false,
        false,
        null,
        null,
        null,
        [],
        v=> {
          v = v.filter((v)=>v.price>0);

          //exclude serviceCat (isShowForOrder)
          let filteredArr = [];       
          function checkIsShowForOrder(item) {    
            if(item.isShowForOrder) {
              if (item.serviceCat!==null) {
                return checkIsShowForOrder(item.serviceCat)
              } else {
                return true;
              }
            } else {
              return false
            }
          }
          v.forEach(item=>{           
            if (checkIsShowForOrder(item)) 
            filteredArr.push(item);
          });


          let arr = [];
          filteredArr.forEach((v1)=>{
            let s = null;
            arr.forEach((v2)=>{
              if (v2.id===v1.serviceCat.id) s = v2;
            });

            if (s===null){
              let breads = [];
              let _breadNames = '';
              let _hasProgram = false;
              if (v1.serviceCat != null) {
                if (v1.serviceCat.serviceCat != null) {
                  if (v1.serviceCat.serviceCat.serviceCat != null) {
                    if (v1.serviceCat.serviceCat.serviceCat.serviceCat != null) {
                      if (v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat != null) {
                        breads.push({
                          id: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.id,
                          text: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name,
                          isProgram: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                          disabled: false,
                        });
                        _breadNames += v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name;
                        if (v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram) _hasProgram = true;
                      }
                      breads.push({
                        id: v1.serviceCat.serviceCat.serviceCat.serviceCat.id,
                        text: v1.serviceCat.serviceCat.serviceCat.serviceCat.name,
                        isProgram: v1.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                        disabled: false,
                      });
                      _breadNames += v1.serviceCat.serviceCat.serviceCat.serviceCat.name;
                      if (v1.serviceCat.serviceCat.serviceCat.serviceCat) _hasProgram = true;
                    }
                    breads.push({
                      id: v1.serviceCat.serviceCat.serviceCat.id,
                      text: v1.serviceCat.serviceCat.serviceCat.name,
                      isProgram: v1.serviceCat.serviceCat.serviceCat.isProgram,
                      disabled: false,
                    });
                    _breadNames += v1.serviceCat.serviceCat.serviceCat.name;
                    if (v1.serviceCat.serviceCat.serviceCat.isProgram) _hasProgram = true;
                  }
                  breads.push({
                    id: v1.serviceCat.serviceCat.id,
                    text: v1.serviceCat.serviceCat.name,
                    isProgram: v1.serviceCat.serviceCat.isProgram,
                    disabled: false,
                  });
                  _breadNames += v1.serviceCat.serviceCat.name;
                  if (v1.serviceCat.serviceCat.isProgram) _hasProgram = true;
                }
                breads.push({
                  id: v1.serviceCat.id,
                  text: v1.serviceCat.name,
                  isProgram: v1.serviceCat.isProgram,
                  disabled: false,
                });
                _breadNames += v1.serviceCat.name;
                if (v1.serviceCat.isProgram) _hasProgram = true;
              }

              arr.push({
                id:v1.serviceCat.id,
                name:v1.serviceCat.name,
                isProgram:v1.serviceCat.isProgram,
                breads:breads,
                _breadNames: _breadNames,
                _hasProgram: _hasProgram,
                serviceCats:[v1]
              });
            } else {
              s.serviceCats.push(v1);
            }
          });

          arr.forEach((v1)=>{
            v1.serviceCats = v1.serviceCats.sort((a,b)=>a.name.localeCompare(b.name));
          });

          this.searchResults = arr
            .sort((a, b)=>a._breadNames.localeCompare(b._breadNames));

          this.catSelects = [];
          this.dataState.stateSuccess();
        }, ()=> {
          this.dataState.stateError();
        }
      );
    },
    selectAdd(serviceCat){
      // this.focusSearchInput();
      console.log(this.selected)
      if (this.selected.findIndex(v => v.id===serviceCat.id)===-1){
        this.selected.push(serviceCat);
        serviceCat._selected = true;
      } else {
        this.selected = this.selected.filter(v => v.id!==serviceCat.id);
        serviceCat._selected = false;
      }
    },
    selectComplete(){
      this.dataState.stateLoading();
      let data = {
        userId:this.userId
      };

      if (this.selected.length>0) {
        let s = '';
        this.selected.forEach((v)=>{
          s +=v.id+',';
        });

        data.serviceCatIds = s.slice(0, -1);
      }


      Api.service.medPlan.medPlanCreate(data, v => {
        this.dataState.stateSuccess();
        this.open=false;
        this.$emit('complete', v.id);
      }, () => {
        this.dataState.stateError();
      });
    },
  }
}
</script>