<template>
  <dialog-editor
      :item.sync="localOpen"
      name="Добавить услугу"
      width="1280"
      fixedHeight contentPaddingRemove
      :submit-text="selected.length>0 ? 'Добавить' : null"
      @submit="selectComplete()"
  >
    <template v-slot:header>
      <v-row class="rowSmall mx-2">
        <v-col>
          <v-text-field
              ref="searchInput"
              v-model="search"
              @keyup="dataState.stateLoading();$tools.throttle(()=>searchAction())" @input="dataState.stateLoading();$tools.throttle(()=>searchAction())"
              placeholder="Введите текст..."
              background-color="white"
              clearable
              outlined dense hide-details="auto">
            <template v-slot:prepend-inner>
              <v-icon>mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col v-if="tabIndex===5" cols="auto">
          <v-sheet width="280" color="transparent">
            <insur-select class="mb-0" @selected="(v)=>{insurContractId=v;searchAction()}" onlyContract single/>
          </v-sheet>
        </v-col>
        <v-col cols="auto" v-if="typeof onlySimpleServices ==='undefined'">
          <checkbox-field name="Только этого специалиста" :value.sync="userspecOnly"/>
        </v-col>
        <v-col cols="auto">
          <v-sheet width="240">
            <com-branch-autocomplete-multiple class="mb-0" :select.sync="comBranchSelect" globalState/>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="mt-2">
          <v-tabs v-model="tabIndex" background-color="transparent" v-if="typeof onlySimpleServices ==='undefined'">
            <v-tab>Обзор</v-tab>
            <v-tab>Простые</v-tab>
            <v-tab>В программах</v-tab>
            <v-tab>ОМС</v-tab>
            <v-tab>ДМС</v-tab>
            <v-tab>Контракт</v-tab>
          </v-tabs>
          <v-tabs v-model="tabIndex" background-color="transparent" v-else>
            <v-tab>Обзор</v-tab>
            <v-tab>Простые</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </template>
    <template v-slot:default="{localItem, height}">
      <div v-if="localItem">
        <services-explorer
            v-if="tabIndex===0"
            ref="servicesExplorer"
            :selectedItems.sync="selected"
            :comBranchSelect="comBranchSelect"
            :height="height"
        />
        <div v-else>
          <progress-page v-if="dataState.isLoading"/>
          <div v-else-if="dataState.isSuccess">
            <div v-if="tabIndex===3 && insurOmsId===null" class="body-1 pa-4 text-center">ОМС не выбрано</div>
            <div v-else-if="tabIndex===4 && insurDmsId===null" class="body-1 pa-4 text-center">ДМС не выбрано</div>
            <div v-else-if="tabIndex===5 && insurContractId===null" class="body-1 pa-4 text-center">Организация не выбрана</div>
            <div v-else-if="searchResults.length===0" class="body-1 pa-4 text-center">Не найдено</div>
            <div v-else>

              <v-expansion-panels accordion v-model="catSelects" multiple class="fill-height">
                <v-expansion-panel
                    v-for="(searchResult, index) in (comBranchSelect.length>0 ? searchResults.filter(v=>v.comBranch===null || comBranchSelect.some(v1 =>v.comBranch.id===v1.id)) : searchResults)"
                    :key="'searchResult'+index"
                >
                  <v-expansion-panel-header>
                    <v-breadcrumbs class="pa-0">
                      <div v-for="(bread, i) in searchResult.breads" :key="i">
                        <v-breadcrumbs-item class="body-2 font-weight-medium">
                          <v-avatar v-if="bread.comBranch!==null" class="mr-2" :color="bread.comBranch.color" size="10" style="margin-top:-1px"/>
                          <chip v-if="bread.isProgram" color="green" dark small class="mr-2">Программа</chip>
                          {{bread.text}}
                        </v-breadcrumbs-item>
                        <v-breadcrumbs-divider v-if="i<searchResult.breads.length-1">/</v-breadcrumbs-divider>
                      </div>
                    </v-breadcrumbs>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list class="pa-0">
                      <v-list-item
                          v-for="service in (comBranchSelect.length>0 ? searchResult.services.filter(v=>v.comBranch===null || comBranchSelect.some(v1 =>v.comBranch.id===v1.id)) : searchResult.services)"
                          :key="'service'+service.id"
                          @click="selectAdd(service)"
                          :class="selected.filter(v=>v.id===service.id).length>0? 'v-list-item--active' : ''"
                      >
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="6" class="body-2 py-3">
                              <v-avatar v-if="service.comBranch!==null" class="mr-2" :color="service.comBranch.color" size="10" style="margin-top:-1px"/>
                              <tooltip v-if="service.type==='serviceComposite'" right>             
                                <v-icon class="mr-2" size="18" style="margin-top:-1px">mdi-label-multiple-outline</v-icon>                   
                                <template v-slot:content>
                                  Составная услуга         
                                </template>
                              </tooltip>
                              <tooltip v-if="service.type==='product'" right>
                                <v-icon class="mr-2" size="16">mdi-basket-outline</v-icon>
                                <template v-slot:content>
                                  Товар          
                                </template>
                              </tooltip>
                              {{service.name}}           

                              <tooltip v-if="!$tools.isEmpty(service.comment)" bottom width="100%">
                                <v-chip  class="mt-1 mr-2" small outlined>
                                  <v-icon small color="grey darken-5">mdi-comment-text-outline</v-icon>
                                  <span class="caption ml-2">{{$tools.truncateText(service.comment,20)}}</span>                         
                                </v-chip>
                                <template v-slot:content>
                                    <vue-markdown table-class="table">{{service.comment}}</vue-markdown>                
                                </template>
                              </tooltip>
                          
                            </v-col>
                            <v-col>
                              <chip>
                                {{$tools.isEmpty(service.code) ? 'код не найден' : service.code }}
                              </chip>
                            </v-col>
                            <v-col class="body-2 pt-3">
                              Длит.: {{service.duration }} мин.
                            </v-col>
                            <v-col class="body-2 pt-3 text-no-wrap">Срок испол.: {{service.resultDuration}} д.</v-col>
                            <v-col class="body-2 py-3 text-right">
                              <div v-if="$tools.isEmpty(service.priceInsur)">{{$tools.price(service.priceTotal)}}</div>
                              <div v-else class="text-no-wrap">
                                <span class="text-decoration-line-through mr-2">{{$tools.price(service.priceTotal)}}</span>
                                {{$tools.price(service.priceInsur)}}
                              </div>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div class="caption py-4 text-center">Показаны последние 250 услуг. Уточните параметры поиска</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footerActions>
      <v-btn
        text
        @click="selected.length>0 ? selectOpen=true : null"
      >Выбрано: Количество:{{selected.length}}, Сумма:{{$tools.price(sum())}}</v-btn>
      <dialog-editor :item.sync="selectOpen" name="Просмотр выделенных элементов" width="1024" footer-hide content-padding-remove>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th>ID</th>
              <th colspan="2">Название</th>
              <th>Код</th>
              <th>Длительность</th>
              <th>Цена</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              <tr
                  v-for="(item, index) in selected"
                  :key="'selected'+index"
              >
                <td>{{item.id}}</td>
                <td class="text-wrap">
                  {{item.name}}
                  <tooltip v-if="!$tools.isEmpty(item.comment)" bottom >       
                      <v-chip  class="mt-1 mr-2" small outlined>
                        <v-icon small color="grey darken-5">mdi-comment-text-outline</v-icon>
                        <span class="caption ml-2">{{$tools.truncateText(item.comment,20)}}</span>                         
                      </v-chip>             
                      <template v-slot:content>
                        <vue-markdown>{{item.comment}}</vue-markdown>   
                      </template>
                  </tooltip>
                </td>
                <td>
                  <div class="d-flex align-center">
                  <tooltip v-if="item.isShowForClient" right>
                    <v-icon small color="grey" class="mr-2">mdi-eye</v-icon>
                    <template v-slot:content>
                      Отображать на сервисах (сайт, моб. приложение)
                    </template>
                  </tooltip>
                  <tooltip v-if="item.isVisitCreateForClient" right>
                    <v-icon small color="grey" class="mr-2">mdi-account-check</v-icon>
                    <template v-slot:content>
                      Возможность самозаписи клиентом
                    </template>
                  </tooltip>
                  <tooltip v-if="item.isPriceLock" right content="Скидка запрещена">
                    <v-icon small color="grey" class="mr-2">mdi-cash-lock</v-icon>
                    <template v-slot:content>
                      Скидка запрещена
                    </template>
                  </tooltip>
                  </div>
                </td>
                <td>
                  <service-code-view :code="item.code"/>
                </td>
                <td>
                  {{typeof item.duration==='undefined' ? '' : item.duration+' мин.'}}
                </td>
                <td class="text-no-wrap">
                  <span v-if="typeof item.priceTotal!=='undefined'">{{$tools.price(item.priceTotal)}}</span>
                </td>
                <td>
                  <v-btn icon @click="selected=selected.filter(v=>v.id!==item.id);selected.length===0 ? selectOpen=null : true"><v-icon small>mdi-close</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </dialog-editor>
    </template>
  </dialog-editor>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import InsurSelect from "@/views/Insur/components/InsurSelect.vue";
// import moment from "moment";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";
import DialogEditor from "@/componentsV2/base/DialogEditor.vue";
import ServiceCodeView from "@/views/Service/ServiceCodeView.vue";
import ServicesExplorer from "@/views/Price/ServicesExplorer.vue";
import ProgressPage from "@/components/ProgressPage.vue";
import Chip from "@/componentsV2/base/Chip.vue";
import axios from "axios";
import Tooltip from "@/componentsV2/base/Tooltip.vue";

export default {
  components: {
    Chip,
    ProgressPage,
    Tooltip,
    ServiceCodeView,
    DialogEditor, 
    ComBranchAutocompleteMultiple, 
    InsurSelect, 
    ServicesExplorer, 
    CheckboxField, 
  },
  props: ['open', 'hideFree', 'userspecId', 'servicesId', 'insurOmsId', 'insurDmsId','visitBegin','visitEnd', 'onlySimpleServices'],
  data: () => ({
    localOpen:null,
    selectOpen:null,
    dataState: new State(),
    search: '',
    insurContractId:null,
    searchResults:[],
    catSelects:[],

    tabIndex:0,
    userspecOnly:false,

    selected:[],
    comBranchSelect:[],
  }),
  watch: {
    open: function() {
      if (this.open){
        setTimeout(()=>{
          this.$refs.searchInput.focus();
        }, 200);
        this.search = '';
        this.searchResults = [];
        this.selected = [];
        this.tabIndex = 0;
        this.insurContractId = null;
      }
      this.localOpen = this.open;
    },
    localOpen(){
      this.$emit('update:open', this.localOpen);
    },
    userspecOnly(){
      this.selected = [];
      this.searchAction();
    },
    tabIndex(){
      if(this.tabIndex!==0)
        this.searchAction();
    },
    comBranchSelect() {
      this.searchAction();
    }
    // selected() {
    //   this.servicesTimeRangeValidation()
    // }
  },
  methods: {
    searchAction(){
      if(!this.$tools.isEmpty(this.search) && this.tabIndex===0)
        this.tabIndex = 1;

      this.dataState.stateLoading();

      this.searchResults = [];
      this.catSelects = [];

      if(this.tabIndex===3 && this.insurOmsId===null){
        this.dataState.stateSuccess();
        return;
      }
      if(this.tabIndex===4 && this.insurDmsId===null){
        this.dataState.stateSuccess();
        return;
      }
      if(this.tabIndex===5 && this.insurContractId===null){
        this.dataState.stateSuccess();
        return;
      }


      let url = Api.host+'/serviceCat/search/?isService=1&';
      if (this.tabIndex===1) url += 'isServiceCatProgramExclude=1&';
      if (this.tabIndex===2) url += 'isServiceCatProgram=1&';
      if (this.tabIndex===3) url += 'insurOmsId='+this.insurOmsId+'&';
      if (this.tabIndex===4) url += 'insurDmsId='+this.insurDmsId+'&';
      if (this.tabIndex===5) url += 'insurContractId='+this.insurContractId+'&';
      if (!this.$tools.isEmpty(this.userspecId)) url += 'userspecId='+this.userspecId+'&';
      if (this.userspecOnly) url += 'userspecOnly=1&';
      if (this.comBranchSelect.length>0) url += 'comBranchIds='+this.comBranchSelect.map(cb => cb.id)+'&';
      if(!this.$tools.isEmpty(this.search)) url += 'q='+this.search;

      axios.get(url,{withCredentials: true})
          .then(r=>{
            let v = r.data;
            if (typeof this.hideFree!=='undefined')
              v = v.filter((v)=>v.priceTotal>0);

            let arr = [];
            v.forEach((v1)=>{
              let s = null;
              arr.forEach((v2)=>{
                if (v2.id===v1.serviceCat.id) s = v2;
              });

              if (s===null){
                let breads = [];
                let _breadNames = '';
                let _hasProgram = false;
                if (v1.serviceCat != null) {
                  if (v1.serviceCat.serviceCat != null) {
                    if (v1.serviceCat.serviceCat.serviceCat != null) {
                      if (v1.serviceCat.serviceCat.serviceCat.serviceCat != null) {
                        if (v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat != null) {
                          breads.push({
                            id: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.id,
                            text: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name,
                            isProgram: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                            comBranch: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                            disabled: false,
                          });
                          _breadNames += v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name;
                          if (v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram) _hasProgram = true;
                        }
                        breads.push({
                          id: v1.serviceCat.serviceCat.serviceCat.serviceCat.id,
                          text: v1.serviceCat.serviceCat.serviceCat.serviceCat.name,
                          isProgram: v1.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                          comBranch: v1.serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                          disabled: false,
                        });
                        _breadNames += v1.serviceCat.serviceCat.serviceCat.serviceCat.name;
                        if (v1.serviceCat.serviceCat.serviceCat.serviceCat) _hasProgram = true;
                      }
                      breads.push({
                        id: v1.serviceCat.serviceCat.serviceCat.id,
                        text: v1.serviceCat.serviceCat.serviceCat.name,
                        isProgram: v1.serviceCat.serviceCat.serviceCat.isProgram,
                        comBranch: v1.serviceCat.serviceCat.serviceCat.comBranch,
                        disabled: false,
                      });
                      _breadNames += v1.serviceCat.serviceCat.serviceCat.name;
                      if (v1.serviceCat.serviceCat.serviceCat.isProgram) _hasProgram = true;
                    }
                    breads.push({
                      id: v1.serviceCat.serviceCat.id,
                      text: v1.serviceCat.serviceCat.name,
                      isProgram: v1.serviceCat.serviceCat.isProgram,
                      comBranch: v1.serviceCat.serviceCat.comBranch,
                      disabled: false,
                    });
                    _breadNames += v1.serviceCat.serviceCat.name;
                    if (v1.serviceCat.serviceCat.isProgram) _hasProgram = true;
                  }
                  breads.push({
                    id: v1.serviceCat.id,
                    text: v1.serviceCat.name,
                    isProgram: v1.serviceCat.isProgram,
                    comBranch: v1.serviceCat.comBranch,
                    disabled: false,
                  });
                  _breadNames += v1.serviceCat.name;
                  if (v1.serviceCat.isProgram) _hasProgram = true;
                }

                arr.push({
                  id:v1.serviceCat.id,
                  name:v1.serviceCat.name,
                  isProgram:v1.serviceCat.isProgram,
                  comBranch: v1.serviceCat.comBranch,
                  breads:breads,
                  _breadNames: _breadNames,
                  _hasProgram: _hasProgram,
                  services:[v1]
                });
              } else {
                s.services.push(v1);
              }
            });

            arr.forEach((v1)=>{
              v1.services = v1.services.sort((a,b)=>a.name.localeCompare(b.name));
              let n = null;
              if(this.tabIndex===3) n = 'oms';
              if(this.tabIndex===4) n = 'dms';
              if(this.tabIndex===5) n = 'contract';
              v1.services.forEach((v2)=>{
                v2.mode = n;
              });
            });


            this.searchResults = arr
                .filter((v)=>!v._hasProgram)
                .sort((a, b)=>a._breadNames.localeCompare(b._breadNames))
                .concat(arr
                    .filter((v)=>v._hasProgram)
                    .sort((a, b)=>a._breadNames.localeCompare(b._breadNames))
                );

            this.catSelects = [];
            if(this.searchResults.length<=2)
              this.catSelects = [0, 1];

            this.dataState.stateSuccess();
          })
          .catch(() =>{
            this.dataState.stateError();
          });
    },

    selectAdd(service){
      this.$refs.searchInput.focus();
      if (this.selected.findIndex(v => v.id===service.id)===-1){
        if(this.tabIndex===3) service.insurId = this.insurOmsId;
        if(this.tabIndex===4) service.insurId = this.insurDmsId;
        if(this.tabIndex===5) service.insurId = this.insurContractId;
        this.selected.push(service);
      } else {
        this.selected = this.selected.filter(v => v.id!==service.id);
      }
    },

    selectComplete(){
      this.localOpen = null;
      let arr = [];
      this.selected.forEach((v)=>{
        arr.push({
          id:v.id,
          name:v.name,
          type:v.type,
          spec: v.spec,
          price: this.$tools.isEmpty(v.priceInsur) ? v.priceTotal : v.priceInsur,
          priceTotal: v.priceTotal,
          mode:v.mode,
          insurId: v.insurId
        });
      });
      this.$emit('complete', arr);
    },

    sum(){
      let r = 0;
      this.selected.forEach(v=>{
        r+= parseFloat(typeof v.priceInsur==='undefined' ? v.priceTotal : v.priceInsur);
      });
      return r;
    }
  }
}
</script>

<style lang="scss" scoped>
._search-btn {
  height: 39px !important;
}
</style>