<template>
  <a
      v-if="typeof link!=='undefined'"
      @click.stop="null"
      :href="'/panel/user/'+type+'/'+user.id+'/'"
      target="_blank"
      class="text-decoration-none"
  >
    <v-icon small>mdi-open-in-new</v-icon>
    <template v-if="(typeof fullName !== 'undefined')">
      {{user.fullName}}
    </template>
    <template v-else>
      {{user.name}}
    </template>
  </a>
  <v-btn
      v-else-if="typeof block!=='undefined'"
      @click.stop="null"
      :href="'/panel/user/'+type+'/'+user.id+'/'"
      target="_blank"
      :color="typeof color==='undefined' ? null : color"
      depressed small
      block
  >
    <v-icon class="pr-2" small>mdi-open-in-new</v-icon>
    <template v-if="(typeof fullName !== 'undefined')">
      {{user.fullName}}
    </template>
    <template v-else>
      {{user.name}}
    </template>
  </v-btn>
  <v-btn
      v-else
      @click.stop="null"
      :href="'/panel/user/'+type+'/'+user.id+'/'"
      target="_blank"
      :color="typeof color==='undefined' ? null : color"
      depressed small
  >
    <v-icon class="pr-2" small>mdi-open-in-new</v-icon>
    <template v-if="(typeof fullName !== 'undefined')">
      {{user.fullName}}
    </template>
    <template v-else>
      {{user.name}}
    </template>
    <v-spacer/>
  </v-btn>
</template>

<script>
export default {
  props: ['user','type','color', 'block', 'link', 'fullName']
}
</script>
