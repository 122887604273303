<template>
    <div>
      <div @click="selected=[];visitServiceCreateState.modalOpen()">  
        <autocomplete-multiple name="Выбрать услуги" :items.sync="selected" :select.sync="selected" textOverflow/>
      </div>
      <visit-service-add
        :open.sync="visitServiceCreateState.isModalOpen"
        @complete="visitServiceCreate"
        onlySimpleServices
      />
    </div>
</template>

<script>
import State from "@/plugins/state";
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";
import VisitServiceAdd from "@/views/Visit/VisitServiceAdd.vue";


export default {
 components: {AutocompleteMultiple, VisitServiceAdd},
 props: ['selectedServices'],
  data: () => ({
    visitServiceCreateState: new State(),
    selected:[]
  }),
  watch:{
    selected(){
      this.$emit('update:selectedServices',this.selected)
      console.log(this.selected);
    }
  },
  methods: {
    visitServiceCreate(services){
      // console.log(services);
      this.selected = services;
    },
  }
}
</script>